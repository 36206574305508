export const LINK_INFO={
    PRIVACY_LINK:{
        "en" : "https://www.sixt.com/ride/privacy-driver/",
        "de" : "https://www.sixt.de/ride/privacy-driver/",
        "fr" : "https://www.sixt.fr/ride/privacy-driver/",
        "es" : "https://www.sixt.es/ride/privacy-driver/",
        "it" : "https://www.sixt.it/ride/privacy-driver/",
    },

    ANALYTICS_LINK:{
        "en" : "https://www.sixt.com/ride/driverapp-analytics/",
        "de" : "https://www.sixt.de/ride/driverapp-analytics/",
        "fr" : "https://www.sixt.fr/ride/driverapp-analytics/",
        "es" : "https://www.sixt.es/ride/driverapp-analytics/",
        "it" : "https://www.sixt.it/ride/driverapp-analytics/",
    }
};