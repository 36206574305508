import React, { useState, useEffect, useContext, Suspense, useCallback, lazy } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { LanguageContext } from "contexts/language";
import get from 'lodash/get';
import {
    Link,
    Route,
    Switch,
    useRouteMatch
} from 'react-router-dom';
import { getNotes } from 'redux/action';
const AddTspNote = lazy(() => import('./addTspNote'));
import ViewModal from './viewModal';
import './styles.scss';

//this component is reponsible for showing multiple company notes for current tsp only for admin
const TspNotes = ({
    tsp_id = "",
    adminFlow,
}) => {
    const { t } = useTranslation();
    const { path, url } = useRouteMatch();
    const [language] = useContext(LanguageContext);
    const dispatch = useDispatch();
    const [showViewModal, setShowViewModal] = useState(false);
    const [viewContent, setViewContent] = useState("");

    const notes = useSelector(state => state.notes || []);

    const getUpdatedNotes = useCallback(() => dispatch(getNotes(tsp_id)), [dispatch, tsp_id]);
    useEffect(() => {
        getUpdatedNotes();
    }, [language, getUpdatedNotes]);

    const columns = [
        {
            dataField: 'createdAt',
            text: t("DATE"),
            headerClasses: '',
            hidden: false,
            sort: true,
            formatter: function dateFormat(cell, row) {
                return (
                    <div className="">
                        {moment(get(row, 'createdAt', "")).format('DD MMM  YY')}
                    </div>
                );
            },
        },
        {
            dataField: 'category',
            text: t("CATEGORY"),
            headerClasses: '',
            hidden: false,
            sort: true,
            formatter: function categoryFormat(cell, row) {
                return (
                    <div className="">
                        {get(row, 'category', "")}
                    </div>
                );
            },
        }, {
            dataField: 'subCategory',
            text: t("SUB_CATEGORY"),
            headerClasses: '',
            hidden: false,
            sort: true,
            formatter: function subCategoryFormat(cell, row) {
                return (
                    <div className="">
                        {get(row, 'subCategory', "")}
                    </div>
                );
            },
        }, {
            dataField: 'user',
            text: t("ADDED_BY"),
            headerClasses: '',
            hidden: false,
            sort: true,
            formatter: function addedByUser(cell, row) {
                const addedBy = get(row, 'user', "");
                return (
                    <div className="">
                        {addedBy}
                    </div>
                );
            }
        },
        {
            dataField: 'Actions',
            text: t("ACTIONS"),
            headerClasses: '',
            hidden: false,
            sort: false,
            formatExtraData: {
                viewText: t("VIEW"),
            },
            formatter: function viewFormat(cell, row, rowIndex, formatExtraData) {

                return (
                    <div className="actions">

                        <button className="actions_view" onClick={(e) => handleContentView(e, row)} >{formatExtraData.viewText}</button>
                    </div>
                );
            },
        }
    ];
    const handleContentView = (e, row) => {
        e.preventDefault();
        setShowViewModal(true);
        setViewContent(row);
    };

    const closeViewModal = () => {
        setShowViewModal(false);
    };

    return (
        <div>
            <Suspense fallback={<div className="vh-100">&nbsp;</div>}>
                <Switch>
                    <Route exact path={path}>
                        <div className="note-page">
                            <div className="note-page-header">
                                <h2 className="note-page-title">{t("COMPANY_NOTES")}</h2>
                                <button className="note-addnew-btn"><Link to={`${url}/add`}>{t("ADD_NEW_NOTE")}</Link></button>
                            </div>
                            <BootstrapTable
                                keyField='date'
                                classes="note-info-table table-striped"
                                columns={columns}
                                data={notes}
                                defaultSorted={[{
                                    dataField: 'createdAt',
                                    order: 'desc'
                                }]}
                            />
                        </div>
                    </Route>
                    <Route exact path={`${path}/add`}>
                        <AddTspNote adminFlow={adminFlow} tsp_id={tsp_id} getUpdatedNotes={getUpdatedNotes} />
                    </Route>
                </Switch>
            </Suspense>

            {showViewModal && (
                <Suspense fallback={<div />}>
                    <ViewModal
                        showViewModal={showViewModal}
                        closeViewModel={closeViewModal}
                        viewContent={viewContent}
                    />
                </Suspense>
            )}
        </div>
    );
};

export default TspNotes;