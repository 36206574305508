import { NOTES } from '../action/types';

const initialState = null;

export default (state = initialState, action = {}) => {
    if(action.type === NOTES){
        return action.notes;
    } else {
        return state;
    }
};