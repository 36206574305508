import { CATEGORY } from '../action/types';

const initialState = null;

export default (state = initialState, action = {}) => {
    if(action.type === CATEGORY){
        return action.category;
    } else {
        return state;
    }
};