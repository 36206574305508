import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { RideDetails } from './rideDetails';

import { ReactComponent as CloseIcon } from 'images/icons/ico_cross_link.svg';
import { ReactComponent as RideNavIcon } from 'images/icons/ico_chevrondown.svg';

import './styles.scss';
import { VAT_INVOICE_STATUS } from '../config';
import { DownloadInvoice } from './downloadInvoice';
import { UploadInvoice } from './uploadInvoice';
import { removeIndexFromArray } from 'helpers';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';


export const InvoiceModal = ({
  rideList = [],
  show,
  onClose
}) => {

  const { t } = useTranslation();

  const [rideNumber, setRideNumber] = useState(0);
  const [updatingInvoice, setUpdatingInvoice] = useState(false);
  const [workingRideList, setWorkingRideList] = useState([...rideList]);
  const [allCommentsVisible, setAllCommentsVisible] = useState(false);
  const [currentRide, setCurrentRide] = useState(workingRideList[rideNumber]); // current ride displayed in the modal
  const [isNextPageAvailable, setIsNextpageAvailable] = useState(false);
  const [isPrevPageAvailable, setIsPrevpageAvailable] = useState(false);
  const [changesCount, setChangesCount] = useState(0);

  const showUploadFile = currentRide?.status === VAT_INVOICE_STATUS.PENDING_REQUEST_TSP_VAT_INVOICE || updatingInvoice;

  useEffect(() => {

    if (workingRideList[rideNumber]) {
      setCurrentRide(workingRideList[rideNumber]);
      setIsNextpageAvailable(rideNumber < workingRideList.length - 1);
      setIsPrevpageAvailable(rideNumber > 0);
    }
  }, [rideNumber, workingRideList]);

  useEffect(() => {
    if (changesCount > 0) {
      const updatedRideList = removeIndexFromArray(workingRideList, rideNumber);
      if (updatedRideList.length > 0) {
        const newRideNumber = rideNumber + 1 > updatedRideList.length - 1 ? updatedRideList.length - 1 : rideNumber + 1;
        setRideNumber(newRideNumber);
        setWorkingRideList(updatedRideList);
      } else {
        closeModal();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changesCount]);

  const showNextRide = () => {
    if (isNextPageAvailable) {
      setRideNumber(rideNumber + 1);
    }
  };

  const showPreviousRide = () => {
    if (isPrevPageAvailable) {
      setRideNumber(rideNumber - 1);
    }
  };

  const getCommentsList = () => {
    let reverseComments = [];
    try {
      reverseComments = (JSON.parse(currentRide.comments || [])).reverse();
    } catch {
      reverseComments = [];
    }
    return allCommentsVisible ? reverseComments : reverseComments.slice(0, 1);
  };



  const closeModal = () => {
    setRideNumber(0);
    setWorkingRideList([]);
    setUpdatingInvoice(false);
    onClose(changesCount > 0);
  };

  const updateInvoice = () => {
    setUpdatingInvoice(true);
  };

  const cancelUpdateInvoice = () => {
    setUpdatingInvoice(false);
  };

  const successHandler = () => {
    setUpdatingInvoice(false);
    setChangesCount(changesCount + 1);
  };

  const viewMoreLessComments = () => {
    setAllCommentsVisible(!allCommentsVisible);
  };

  const getRejectionCommentsSection = () => {
    const commentList = getCommentsList();
    return (
      commentList.length > 0 &&
      <div className='rejection-comment-section'>
        <label>{t('REASON_FOR_INVOICE_UPDATE')}: </label>
        <div className='rejection-comments'>
          {getCommentsList().map(comment =>
            <div className='rejection-comment' key={comment.updated_at}>
              <span className='comment'>{comment.note}</span>
              <span className='comment-date'>{moment(comment.updated_at).format('DD/MM/YYYY HH:mm')}</span>
            </div>
          )}

        </div>
        <span className='view-more' onClick={viewMoreLessComments}>{allCommentsVisible ? t('VIEW_LESS') : t('VIEW_MORE')}</span>
      </div>
    );
  };

  const getModalComponent = () => (
    <Modal show={show} onHide={closeModal} backdrop="static" dialogClassName="vat-invoice-dialog"
      animation={false}>
      <div className='modal-container'>
        <div className='invoice-nav-btn'>
          <RideNavIcon className={`btn-prev ${!isPrevPageAvailable ? 'disabled' : ''}`} onClick={showPreviousRide} />
        </div>
        <div className='modal-main'>
          <div className='invoice-modal-header'>
            <h3>{t('VAT_INVOICE')}</h3>
            <CloseIcon className='cp' onClick={closeModal} />
          </div>
          <Modal.Body>
            <RideDetails rideData={currentRide} />
            {getRejectionCommentsSection()}
            {
              showUploadFile ?
                <UploadInvoice rideData={currentRide} cancelUpdateInvoice={cancelUpdateInvoice} updatingInvoice={updatingInvoice} onUploadSuccess={successHandler} /> :
                <DownloadInvoice rideData={currentRide} updateInvoice={updateInvoice} successHandler={successHandler} />
            }

          </Modal.Body>
        </div>
        <div className='invoice-nav-btn'>
          <RideNavIcon className={`btn-next ${!isNextPageAvailable ? 'disabled' : ''}`} onClick={showNextRide} />
        </div>
      </div>
    </Modal>
  );


  return (
    currentRide ? getModalComponent() : <></>
  );
};