import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import classNames from 'classnames';
import remove from 'lodash/remove';

import { useOutsideClick } from 'helpers';
import DownArrow from 'images/icons/ico_chevrondown.svg';
import CloseIcon from 'images/icons/ico_smallcross.svg';
import './styles.scss';

const FilterBox = ({
    list = [],
    selectedItems = [],
    icon,
    label = "",
    closeOnSelection = false,
    multipleSelect = false,
    searchPlaceholder = "SEARCH",
    callback = () => {
        // default implementation for callback if not provided in props
    }
}) => {

    const [searchText, setSearchText] = useState("");
    const [showDropdown, setShowDropdown] = useState(false);
    const [filteredList, setFilteredList] = useState(list);

    const { t } = useTranslation();
    const searchInput = useRef(null);

    // Outside click management
    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef, () => setShowDropdown(false));

    useEffect(() => {
        if (showDropdown) {
            searchInput.current.focus();
        }
    }, [showDropdown]);

    useEffect(() => {
      if (list && list.length > 0) {
        const uniqueFilteredList = [
          ...new Set(list.filter(item => item.toLowerCase().includes(searchText.toLowerCase()))),
        ];
        setFilteredList(uniqueFilteredList);
      }
    }, [list, searchText]);

    function handleListItemSelection(e, item) {
        e.stopPropagation();

        if (multipleSelect) {
            let updatedList;
            if (selectedItems?.includes(item)) { // Remove item
                remove(selectedItems, function (ListItem) {
                    return ListItem === item;
                });
                updatedList = selectedItems;
            } else { // Add item
                updatedList = selectedItems.concat(item);
            }
            callback(updatedList);
        } else {
            
            let updatedList;
            if (selectedItems?.includes(item)) { // Remove item
                remove(selectedItems, function (ListItem) {
                    return ListItem === item;
                });
                updatedList = selectedItems;
                callback(updatedList);
            } else { // Add item
                callback([item]);
            }
            
        }

        if (closeOnSelection) {
            setShowDropdown(false);
        }
    }

    function clearSearchText(e) {
        e.stopPropagation();
        setSearchText("");
        searchInput.current.focus();
    }

    return (
        <div
            className="filter-box-container"
            ref={wrapperRef}
            onClick={() => setShowDropdown(!showDropdown)}
        >
            <div className="filter-heading" >
                <div className="logo">
                    <img
                        className="menu-icon"
                        src={icon}
                        alt={label}
                    />
                </div>
                <div className="filter-label">
                    <span className = "filter-label-text">{label}</span>
                    <img className="dropdown-icon" src={DownArrow} alt="down" />
                </div>
            </div>
            <div className={classNames("dropdown", { hide: !showDropdown })}>
                <div className="search-box">
                    <input
                        ref={searchInput}
                        type="text"
                        className="input-search"
                        placeholder={t(searchPlaceholder)}
                        onClick={(e) => e.stopPropagation()}
                        onChange={e => setSearchText(e.target.value)}
                        value={searchText}
                    />
                    {searchText.length > 0 && (
                        <div className="clear" onClick={clearSearchText}>
                            <img src={CloseIcon} alt="" />
                        </div>
                    )}
                </div>
                <ul className="dropdown-list">
                    {filteredList.map(item => (
                        <li
                            className={classNames("list-item", {
                                selected: selectedItems.includes(item)
                            })}
                            key={item}
                            onClick={(e) => handleListItemSelection(e, item)}
                        >
                            {item}
                        </li>
                    ))}
                    <li
                        className={classNames("list-item not-found", {
                            hide: filteredList.length !== 0
                        })}
                    >
                        Not found
                    </li>
                </ul>
            </div>
        </div >
    );
};

export default FilterBox;