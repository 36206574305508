import { SUPPORT_NUMBERS, LOGOUT, DISPATCHER_DETAILS } from '../action/types';

export default (state = null, action = "") => {
    switch (action.type) {
        case DISPATCHER_DETAILS: {
            return {
                ...state,
                dispatcher: action.dispatcher
            };
        }
        case SUPPORT_NUMBERS: {
            return {
                ...state,
                supportNumbers: action.supportNumbers
            };
        }
        case LOGOUT: {
            return null;
        }
        default:
            return state;
    }
};