import { getCurrencySymbol } from 'helpers';
import moment from 'moment-timezone';
import { formatPrice, VAT_INVOICE_STATUS } from 'pages/vatInvoice/config';
import React from 'react';
import { useTranslation } from 'react-i18next';

import './styles.scss';

export const RideDetails = ({ rideData }) => {

  const { t } = useTranslation();

  const costCenter = rideData.costCentre || [{ code: '', value: '' }];

  return (
    <div className='ride-details'>
      <div className='ride-details-row'>
        <div className='ride-details-item'>
          <span className='item-value'>{rideData.reservationId}</span>
          <span className='item-label'>{t('SIXT_RIDE_ID')}</span>
        </div>
        <div className='ride-details-item'>
          <span className='item-value'>{moment(rideData.rideStartAt).format('DD/MM/YYYY HH:mm')}</span>
          <span className='item-label'>{t('DATE_TIME_RIDE_START')}</span>
        </div>
        <div className='ride-details-item'>
          <span className='item-value'>{rideData.travellingCustomerName || <>&mdash;</>}</span>
          <span className='item-label'>{t('TRAVELLING_CUSTOMER_NAME')}</span>
        </div>
        {costCenter?.map((costCentre, index) => (
          <div className='ride-details-item' key={costCentre.code}>
            <span className='item-value'>{costCentre.code ? `${costCentre.code}(${costCentre.value}%)` : '-'}</span>
            <span className='item-label'>{t('COST_CENTER')} {costCenter.length > 1 ? index + 1 : ''}</span>
          </div>
        ))}
        <div className='ride-details-item'>
          <span className='item-value'>
            {`${getCurrencySymbol(rideData.currency)} ${formatPrice(rideData.extras)}`}
          </span>
          <span className='item-label'>{t('EXTRA_COST')}</span>
        </div>
        <div className='ride-details-item'>
          <span className='item-value'>{`${getCurrencySymbol(rideData.currency)} ${formatPrice(rideData.initialCost)}`}</span>
          <span className='item-label'>{t('INITIAL_COST')}</span>
        </div>
        <div className='ride-details-item'>
          <span className='item-value'>{`${getCurrencySymbol(rideData.currency)} ${formatPrice(rideData.finalCost)}`}</span>
          <span className='item-label'>{t('FINAL_RIDE_AMOUNT')}</span>
        </div>
      </div>

      <div className='ride-details-location-row'>
        <div className='ride-details-item'>
          <span className='item-value'>{rideData.origin || <>&mdash;</>}</span>
          <span className='item-label'>{t('START_LOCATION')}</span>
        </div>
        <div className='ride-details-item'>
          <span className='item-value'>{rideData.destination || <>&mdash;</>}</span>
          <span className='item-label'>{t('DROP_LOCATION')}</span>
        </div>
      </div>

      <div className='ride-details-address-row'>
        <div className='ride-details-item'>
          <span className='item-label'>{t('BILLING_INVOICE_ADDRESS')}:</span>
          <span className='item-value'>{rideData.billingAddress || <>&mdash;</>}</span>
        </div>
      </div>

      {rideData.status === VAT_INVOICE_STATUS.APPROVED_TSP_VAT_INVOICE &&
        <div className='ride-details-location-row'>
          <div className='ride-details-item'>
            <span className='item-value'>{rideData.invoiceNumber || <>&mdash;</>}</span>
            <span className='item-label'>{t('INVOICE_NUMBER')}</span>
          </div>
          <div className='ride-details-item'>
            <span className='item-value'>{rideData.invoiceDate ? moment(rideData.invoiceDate).format('DD/MM/YYYY') : <>&mdash;</>}</span>
            <span className='item-label'>{t('INVOICE_DATE')}</span>
          </div>
        </div>
      }

    </div>
  );
};