import get from "lodash/get";
import moment from "moment-timezone";

export const getCsvColumns = (drivers = [], isCountryUnitedStates, isPastRidesTab=false) => 
    {
        let csvColumns = [
            {
                dataField: 'requested_start_date',
                hidden: true,
                csvText: 'Date Time',
                csvFormatter: (_, row) => {
                    return `${moment(get(row, 'requested_start_date')).format('DD-MM-YYYY HH:mm:ss')}`;
                },
            },
            {
                dataField: 'buffer_time',
                hidden: true,
                csvText: 'Buffer Time',
                csvType: Number,
            },
            {
                dataField: 'reservation_id',
                hidden: true,
                csvText: 'Reservation ID',
                csvType: Number,
            },
            {
                dataField: 'flight_number',
                hidden: true,
                csvText: 'Flight Number',
            },
            {
                dataField: 'origin',
                hidden: true,
                csvText: 'Pickup',
                csvFormatter: (_, row) => {
                    return get(row, 'origin.name');
                },
            },
            {
                dataField: 'destination',
                hidden: true,
                csvText: 'Drop',
                csvFormatter: (_, row) => {
                    return get(row, 'destination.name', '');
                },
            },
            {
                dataField: 'driver_price',
                hidden: true,
                csvText: 'Payout',
                csvFormatter: (_, row) => {
                    const { currency = '' } = row;
                    // condition for fixed price rides
                    const rowDriverPrice =
                        row?.driver_price_details?.pricing_strategy === 'frp'
                            ? row.driver_price_details.driver_price
                            : row.driver_price;
                    let driverPrice = rowDriverPrice ? parseFloat(rowDriverPrice / 100).toFixed(2) : 0;
                    return driverPrice ? `${currency} ${driverPrice}` : `${currency} 0`;
                },
                csvType: Number,
            },
            {
                dataField: 'comment',
                hidden: true,
                csvText: 'Comments',
            },
            {
                dataField: 'requisites',
                hidden: true,
                csvText: 'Number of luggage',
                csvFormatter: (cell, row) => {
                    const { requisites = {} } = row;
                    const { number_of_baggage = 0 } = requisites || {};
                    return number_of_baggage;
                },
                csvType: Number,
            },
            {
                dataField: 'requisites',
                hidden: true,
                csvText: 'Number of passenger',
                csvFormatter: (_, row) => {
                    return get(row, 'requisites.number_of_passengers', 0);
                },
                csvType: Number,
            },
            {
                dataField: 'requisites',
                hidden: true,
                csvText: 'Baby Seats',
                csvFormatter: (_, row) => {
                    return get(row, 'requisites.child_seats.baby', 0);
                },
            },
            {
                dataField: 'requisites',
                hidden: true,
                csvText: 'Child Seats',
                csvFormatter: (_, row) => {
                    return get(row, 'requisites.child_seats.child', 0);
                },
            },
            {
                dataField: 'requisites',
                hidden: true,
                csvText: 'Booster Seats',
                csvFormatter: (_, row) => {
                    return get(row, 'requisites.child_seats.booster', 0);
                },
            },
            {
                dataField: 'driver_id',
                hidden: true,
                csvText: 'Driver',
                csvFormatter: (_, row) => {
                    const { driver_id = '' } = row || {};
                    const { first_name = '', last_name = '' } =
                        drivers.find(driver => driver.driver_id === driver_id) || {};

                    return `${first_name} ${last_name}`;
                },
            },
            {
                dataField: 'vehicle',
                hidden: true,
                csvText: 'Vehicle',
                csvFormatter: (_, row) => {
                    return get(row, 'vehicle.license_number', '');
                },
            },
            {
                dataField: 'vehicle',
                hidden: true,
                csvText: 'Vehicle Manufacturer',
                csvFormatter: (_, row) => {
                    return get(row, 'vehicle.manufacturer', '');
                },
            },
            {
                dataField: 'vehicle_acriss_type_info',
                hidden: true,
                csvText: 'Acriss code',
                csvFormatter: (_, row) => {
                    return get(row, 'vehicle_acriss_type_info.acriss_code', '');
                },
                csvType: Number,
            },
            {
                dataField: 'vehicle_acriss_type_info',
                hidden: true,
                csvText: 'Class',
                csvFormatter: (_, row) => {
                    return get(row, 'vehicle_acriss_type_info.title', '');
                },
            },
            {
                dataField: 'expected_distance',
                hidden: true,
                csvText: isCountryUnitedStates ? 'Expected Distance(in miles)' : 'Expected Distance(in kms)',
                csvFormatter: (_, row) => {
                    const { expected_distance = 0 } = row;
                    const metersPerMile = 1609.344;
                    const metersPerKiloMetre = 1000;
                    const expectedDistanceInMiles = (expected_distance / metersPerMile).toFixed(3);
                    const expectedDistanceInKiloMetres = (expected_distance / metersPerKiloMetre).toFixed(3);
                    const actualDistance = isCountryUnitedStates ? expectedDistanceInMiles : expectedDistanceInKiloMetres;
                    return actualDistance;
                },
            },
            {
                dataField: 'duration',
                hidden: true,
                csvText: 'Duration(in hrs)',
                csvFormatter: (_, row) => {
                    const { duration = 0 } = row;
                    const durationInHours = (duration / 60).toFixed(3);
                    return durationInHours;
                },
            },
            {
                dataField: 'type',
                hidden: true,
                csvText: 'Type',
            },
            {
                dataField: 'accepted_date_time',
                hidden: true,
                csvText: 'Accepted Date',
            },
        ];

    if (isPastRidesTab) {
        csvColumns.push(
            {
                dataField: 'start_date_time',
                hidden: true,
                csvText: 'Start Date',
            },
            {
                dataField: 'end_date_time',
                hidden: true,
                csvText: 'End Date',
            }
        );
    }
    return csvColumns;
};