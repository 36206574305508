import get from 'lodash/get';

import { toastLogout } from 'helpers';
import { COUNTRY } from './types';
import * as service from 'services/tsp';


export const getCountryList = () => async dispatch => {
    try {
        const response = await service.getCountries();
        if (response) {
            dispatch({
                type: COUNTRY,
                countries: get(response, 'data.countries')

            });
            return response.data;
        }
    } catch (error) {
        toastLogout(error);
    }
};




