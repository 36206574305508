import { ZONE_TYPES } from 'enums';
import { get } from 'lodash';
import { getCurrentUserExtId } from 'pages/vatInvoice/service';
import { goOrangeAxios } from './axios';

export const getCountries = async function () {
    return goOrangeAxios.get('/v1/mydriver-supply/countries');
};

export const getStateList = async function (id) {
    return goOrangeAxios.get(`v1/mydriver-supply/country/${id}/states`);
};

export const getBankingSchema = async function (id) {
    return goOrangeAxios.get(`v1/mydriver-supply/tsps/${id}/banking-schema`);
};

export const getNewBankingSchema = async function (id) {
    return goOrangeAxios.get(`v1/ride-invoice/banking-schema/${id}`);
};

export const getAvailableAreas = async function (areaName, areaTypes = [ZONE_TYPES.CITY, ZONE_TYPES.COUNTRY], npsAreaTypes = [ZONE_TYPES.MARKET, ZONE_TYPES.COUNTRY]) {
    const response = await goOrangeAxios.post(`v1/mydriver-supply/zone/details`, {
        "area_names": [areaName],
        "area_types": areaTypes,
        "nps_area_types": npsAreaTypes
    });

    const allAreas = response?.data?.areas_by_attribute || [];
    const matchedAreaDetails = allAreas.find(areaDetails => areaDetails.area_name?.toUpperCase() === areaName?.toUpperCase());
    return matchedAreaDetails?.area || [];
};

export const getCategories = async function () {
    return goOrangeAxios.post('v1/mydriver-supply/tsp/notes-categories', {
        category_ids: []
    });
};

export const addOrUpdateTsp = async function (body) {
    return goOrangeAxios.post('v1/mydriver-supply/tsp', body);
};

export const getTSPByExt = async function (extId = '') {
    return goOrangeAxios.get(`v1/mydriver-supply/tsps/${extId}`);
};

export const getAreaByTSPId = async function (tspId = '') {

    return goOrangeAxios.get(`v1/mydriver-supply/tsp/${tspId}/areas`);
};

export const getBankingSchemaByExt = async function (extId = '') {
    return goOrangeAxios.get(`v1/mydriver-supply/tsps/${extId}/bank-details`);
};

export const getTspList = async function (body, signal) {
    return goOrangeAxios.post('v1/mydriver-supply/tsps', body, { signal });
};

export const requestValidateDac7Fields = async function (body) {
    return goOrangeAxios.post('v1/mydriver-supply/validate-dac7-fields', body);
};

export const getCurrentTspCriticality = async function () {
    const tspExtId = getCurrentUserExtId();
    try {
        const response = await getTSPByExt(tspExtId);
        return get(response, 'data.noMandatoryCheckNoEmailRequired', false);
    } catch {
        return false;
    }
};
