import { RESERVATIONS, RESERVATIONS_LOADED, RESERVATIONS_LOADING } from './types';
import * as service from 'services/reservations';
import { toastLogout } from 'helpers';

export const getReservations = (params) => async dispatch => {
    try {
        dispatch({ type: RESERVATIONS_LOADING });
        const response = await service.getReservations(params);
        if (response) {
            dispatch({
                type: RESERVATIONS,
                reservations: response.data.reservations
            });
            return response.data;
        }
    } catch (error) {
        toastLogout(error);
    } finally {
        dispatch({ type: RESERVATIONS_LOADED });
    }
};

export const clearReservations = () => {
    return {
        type: RESERVATIONS,
        reservations: null
    };
};
