export function storeData(key, data) {
    if (!window.localStorage || !window.JSON || !key) {
        return;
    }
    localStorage.setItem(key, JSON.stringify(data));
}

export function getData(key) {
    try{
        if (!window.localStorage || !window.JSON || !key) {
            return;
        }
        const item = localStorage.getItem(key);
    
        if (!item) {
            return;
        }
    
        return JSON.parse(item);
    } catch (err){
        console.log(err);
    }
   
}

export function removeData(key) {
    if (!window.localStorage || !window.JSON || !key) {
        return;
    }
    localStorage.removeItem(key);
}

export function storeSessionData(key, data) {
    if (!window.sessionStorage || !window.JSON || !key) {
        return;
    }
    sessionStorage.setItem(key, JSON.stringify(data));
}

export function getSessionData(key) {
    try{
        if (!window.sessionStorage || !window.JSON || !key) {
            return;
        }
        const item = sessionStorage.getItem(key);
    
        if (!item) {
            return;
        }
    
        return JSON.parse(item);
    } catch (err){
        console.log(err);
    }
   
}

export function removeSessionData(key) {
    if (!window.sessionStorage || !window.JSON || !key) {
        return;
    }
    sessionStorage.removeItem(key);
}
