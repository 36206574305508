import React, { useRef, useEffect, useContext } from 'react';
import { useTranslation } from "react-i18next";
import moment from 'moment-timezone';
import TimeField from 'react-simple-timefield';
import DatePicker, { CalendarContainer } from "react-datepicker";
import { enUS, de, es, fr, it, nl } from 'date-fns/locale';
import "react-datepicker/dist/react-datepicker.css";

import CalendarIcon from 'images/icons/ico_calendar_gray.svg';
import { LanguageContext } from "contexts/language";
import './styles.scss';

const locales = {
    en: enUS,
    de,
    fr,
    es,
    it,
    nl
};

const CustomTimeInput = ({ value, onChange }) => (
    <TimeField
        value={value}
        onChange={(event, val) => onChange(val)}
        colon=":"
        className="react-datepicker-time__input"
        style={{ "width": "100px" }}
    />
);

const DateFilter = ({
    fromDate = new Date(),
    toDate = new Date(),
    setFromDate = () => {
        // default implemenation for setFromDate
    },
    setToDate = () => {
        // default implemenation for setToDate
    },
    callback = () => {
        // default implemenation for callback
    },
    showDaySelectorButton = false,
    shouldCloseOnSelect = false,
}) => {

    const { t } = useTranslation();
    const [language] = useContext(LanguageContext);

    // Outside click management
    const wrapperRef = useRef(null);

    useEffect(() => {
        callback(fromDate, toDate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fromDate, toDate]);

    function selectSingleDay(e, isToday) {
        const addDays = isToday ? 0 : 1;
        setFromDate(moment().add(addDays, 'days').startOf('day'));
        setToDate(moment().add(addDays, 'days').endOf('day'));
    }

    const CustomCalendarContainer = ({ className, children }) => {
        return (
            <CalendarContainer className={className}>
                {showDaySelectorButton && (
                    <div className="button-container">
                        <button
                            className="calendar-button today"
                            onClick={(e) => selectSingleDay(e, true)}
                        >
                            {t("TODAY")}
                        </button>
                        <button
                            className="calendar-button tomorrow"
                            onClick={selectSingleDay}
                        >
                            {t("TOMORROW")}
                        </button>
                    </div>
                )}
                <div style={{ position: "relative" }}>{children}</div>
            </CalendarContainer>
        );
    };

    return (
        <div className="date-filter-container">
            <span className="filter-title">{`${t("DATE")}:`}</span>
            <div
                className="date-picker-container"
                ref={wrapperRef}
            >
                <div className="filter-heading" >
                    <div className="logo">
                        <img
                            className="menu-icon"
                            src={CalendarIcon}
                            alt={t("DATE_TIME")}
                        />
                    </div>
                    <div className="filter-label">
                        <DatePicker
                            selected={fromDate}
                            onChange={date => {
                                if (moment(toDate).isBefore(date)) {
                                    setToDate(moment(date).add(1, 'd'));
                                }
                                setFromDate(date);
                            }}
                            dateFormat="d MMM yyyy - HH:mm"
                            //timeFormat="HH:mm"
                            timeInputLabel={t("TIME")}
                            locale={locales[language]}
                            showTimeInput
                            shouldCloseOnSelect={shouldCloseOnSelect}
                            showDisabledMonthNavigation
                            calendarContainer={CustomCalendarContainer}
                            customTimeInput={<CustomTimeInput />}
                        />
                    </div>
                </div>
            </div>
            <div className="separator">—</div>
            <div
                className="date-picker-container"
                ref={wrapperRef}
            >
                <div className="filter-heading" >
                    <div className="logo">
                        <img
                            className="menu-icon"
                            src={CalendarIcon}
                            alt={t("DATE_TIME")}
                        />
                    </div>
                    <div className="filter-label">
                        <DatePicker
                            selected={toDate}
                            minDate={fromDate}
                            onChange={date => setToDate(date)}
                            dateFormat="d MMM yyyy - HH:mm"
                            //timeFormat="HH:mm"
                            timeInputLabel={t("TIME")}
                            locale={locales[language]}
                            showTimeInput
                            shouldCloseOnSelect={shouldCloseOnSelect}
                            showDisabledMonthNavigation
                            customTimeInput={<CustomTimeInput />}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DateFilter;
