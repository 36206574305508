import React from 'react';
import { useTranslation } from "react-i18next";
import './styles.scss';

const ClearFilter = ({ 
    buttonText = "RESET_ALL",
    onCLick = () => {
        // this is the default function if not provided in props
    } }) => {

    const { t } = useTranslation();

    return (
        <div className="clear-filter" onClick={onCLick} >
            {/* <img src={CloseIcon} alt="Close" className="close-icon" /> */}
            <span className="close-text">{t(buttonText)}</span>
        </div >
    );
};

export default ClearFilter;