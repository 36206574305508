import React, { useState, Suspense, lazy ,useContext} from 'react';
import { useTranslation } from "react-i18next";

import { sendGoogleAnalyticsEvent, getData } from 'helpers';
import AppStoreBadge from 'images/app-store-badge.png';
import playStoreBadge from 'images/google-play-badge.png';
import packageJson from '../../../package.json';
import { LanguageContext } from "contexts/language";
import {LINK_INFO} from "./ConstantLinkInfo.js";
import './styles.scss';

const Footer = () => {

    const { t } = useTranslation();
    const { dispatcher_id = "" } = getData("USER") || {};
    const [showModal, setShowModal] = useState(false);
    const [language] = useContext(LanguageContext);

    function handleDownloadAppClick(source) {
        sendGoogleAnalyticsEvent("download_app", {
            event_category: "footer",
            event_label: `app_install_link_${source}`
        });
    }

    function footerLinkClick(sectionHeader, link) {
        sendGoogleAnalyticsEvent(sectionHeader, {
            event_category: "footer",
            event_label: link
        });
    }

    const ContactSupport = lazy(() => import('components/contactSupport'));
    function openContactSupport(e) {
        e.preventDefault();
        e.stopPropagation();
        setShowModal(true);
        footerLinkClick("product_help", "contact_support");
    }

    function closeModal() {
        setShowModal(false);
    }

    return (
        <footer className="sitemap">
            <div className="column">
                <h4 className="heading">{t("COMPANY")}</h4>
                <div className="list">
                    <a
                        target="_blank" rel="noopener noreferrer"
                        onClick={() => footerLinkClick("company", "sixt_partners")}
                        href="https://www.sixt.com/sixt-partners/#/"
                        className="link SIXT_PARTNERS"
                    >
                        {t("SIXT_PARTNERS")}
                    </a>
                    <a
                        target="_blank" rel="noopener noreferrer"
                        onClick={() => footerLinkClick("company", "privacy_policy")}
                        href={LINK_INFO.PRIVACY_LINK[language]}
                        className="link PRIVACY_POLICY"
                    >
                        {t("PRIVACY_POLICY")}
                    </a>

                    <a
                        target="_blank" rel="noopener noreferrer"
                        onClick={() => footerLinkClick("company", "analytics_notice")}
                        href={LINK_INFO.ANALYTICS_LINK[language]}
                        className="link ANALYTICS_NOTICE"
                    >
                        {t("ANALYTICS_NOTICE")}
                    </a>

                    <a
                        target="_blank" rel="noopener noreferrer"
                        onClick={() => footerLinkClick("company", "terms_of_service")}
                        href="http://www.sixt.com/ride/tsp/tc_en.pdf"
                        className="link TERMS_OF_SERVICE"
                    >
                        {t("TERMS_OF_SERVICE")}
                    </a>
                    <a
                        target="_blank" rel="noopener noreferrer"
                        onClick={() => footerLinkClick("company", "corporate_responsibility")}
                        href="https://drying-little-tears.org/en/"
                        className="link CORPORATE_RESPONSIBILITY"
                    >
                        {t("CORPORATE_RESPONSIBILITY")}
                    </a>
                </div>
            </div>

            <div className="column">
                <h4 className="heading">{t("SERVICES")} </h4>
                <div className="list">
                    <a
                        target="_blank" rel="noopener noreferrer"
                        onClick={() => footerLinkClick("services", "become_a_partner")}
                        href="https://sixt.secure.force.com/SixtridePartners/"
                        className="link BECOME_A_PARTNER"
                    >
                        {t("BECOME_A_PARTNER")}
                    </a>
                    <a
                        target="_blank" rel="noopener noreferrer"
                        onClick={() => footerLinkClick("services", "rent")}
                        href="https://www.sixt.com"
                        className="link RENT"
                    >
                        {t("RENT")}
                    </a>
                    <a
                        target="_blank" rel="noopener noreferrer"
                        onClick={() => footerLinkClick("services", "share")}
                        href="https://www.sixt.com/share"
                        className="link SHARE"
                    >
                        {t("SHARE")}
                    </a>
                    <a
                        target="_blank" rel="noopener noreferrer"
                        onClick={() => footerLinkClick("services", "ride")}
                        href="https://www.sixt.de/ride"
                        className="link RIDE"
                    >
                        {t("RIDE")}
                    </a>
                </div>
            </div>

            <div className="column">
                <h4 className="heading">{t("PRODUCT_HELP")}</h4>
                <div className="list">
                    <p
                        className="para CONTACT_SUPPORT"
                        onClick={openContactSupport}
                    >
                        {t("CONTACT_SUPPORT")}
                    </p>
                    <a
                        target="_blank" rel="noopener noreferrer"
                        onClick={() => footerLinkClick("product_help", "feedback")}
                        href={`https://sixt.getfeedback.com/r/B8mZ4nnu?dispatcher-id=${dispatcher_id}&platform=WEB`}
                        className="link FEEDBACK"
                    >
                        {t("FEEDBACK")}
                    </a>
                    <a
                        target="_blank" rel="noopener noreferrer"
                        onClick={() => footerLinkClick("product_help", "driver_faqs")}
                        href={`https://sixtridefaq.helpsite.com`}
                        className="link DRIVER_FAQS"
                    >
                        {t("DRIVER_FAQS")}
                    </a>
                    <a
                        target="_blank" rel="noopener noreferrer"
                        onClick={() => footerLinkClick("product_help", "owner_faqs")}
                        href={`https://sixtridefaqowner.helpsite.com`}
                        className="link OWNER_FAQS"
                    >
                        {t("OWNER_FAQS")}
                    </a>
                </div>
            </div>

            <div className="column">
                <h4 className="heading">{t("SIXT_DISPATCHER_APP")}</h4>
                <p className="para"> {t("GO_MOBILE_WITH_OUR_NEW_DISPATCHER_APP")} </p>
                <div className="app-links">
                    <a
                        className="download-link android"
                        href="https://play.google.com/store/apps/details?id=com.mydriver.dispatcher"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => handleDownloadAppClick("android")}
                    >
                        <img className="playStoreBadge" src={playStoreBadge} alt="" />
                    </a>
                    <a
                        className="download-link ios"
                        href="https://apps.apple.com/in/app/sx-dispatcher/id1484304291"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => handleDownloadAppClick("ios")}
                    >
                        <img className="appStoreBadge" src={AppStoreBadge} alt="" />
                    </a>
                </div>
            </div>
            <div className="version">
                {t("VERSION")} {packageJson.version}
            </div>
            <Suspense fallback={<div />}>
                <ContactSupport {...{ showModal, closeModal }} />
            </Suspense>
        </footer>
    );
};

export default Footer;