import get from 'lodash/get';

import { DRIVERS, DRIVERS_LOADED, DRIVERS_LOADING } from './types';
import * as service from 'services/drivers';
import { toastLogout } from 'helpers';

export const getDrivers = (tspId, params) => async dispatch => {
    try {
        dispatch({ type: DRIVERS_LOADING });
        let response = await service.getDrivers(tspId, params);
        if (response) {
            dispatch({
                type: DRIVERS,
                drivers: get(response, 'data.drivers')
            });
            return response.data;
        }
    } catch (error) {
        toastLogout(error);
    } finally {
        dispatch({ type: DRIVERS_LOADED });
    }
};

export const clearDrivers = () => {
    return {
        type: DRIVERS,
        drivers: null
    };
};
