import React from 'react';
import './styles.scss';

import { ReactComponent as LoadingIcon } from 'images/loading.svg';

const OverlayLoader = ({ label }) => (
  <div className="overlay-loader">
    <LoadingIcon className='loading-icon' />
    {label && <span className='loading-label'>{label}</span>}
  </div>
);

export default OverlayLoader;