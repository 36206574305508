import { goOrangeAxios } from "services/axios";

/**
 * **** VAT INVOICE Request Details
 VatInvoiceRideDetailsRequest{
   TspExtId               string   
   Status              []string 
   RideId              string  
   CustomerId          string  
   CustomerCompanyName string   
   Limit               int32    
   Offset              int32    
   RideDate            RideDate 
}
RideDate{
   From string 
   To   string
}
 */
export const getVatInvoiceRides = async (request, signal) => {
  return goOrangeAxios.post('v1/ride-invoice/invoice/vat-invoice-ride-details', request, { signal });
};

export const downloadVatInovice = async (fileId, signal) => {
  return goOrangeAxios.get(`v1/ride-invoice/invoice/${fileId}`, { responseType: 'blob', signal });
};

export const uploadVatInvoice = async (file, reservationId, invoiceNumber, invoiceDate) => {
  const formData = new FormData();
  formData.append('files', file);
  formData.append('invoiceNumber', invoiceNumber);
  formData.append('invoiceDate', invoiceDate);
  const config = { headers: { 'Content-Type': 'multiple/form-data' } };
  return goOrangeAxios.post(`v1/ride-invoice/invoice/vat-invoice/${reservationId}`, formData, config);
};

export const updateVatInvoiceStatus = async (reservationId, reqBody) => {
  return goOrangeAxios.put(`v1/ride-invoice/invoice/vat-invoice/status/${reservationId}`, reqBody);
};

export const checkCurrentUserIsAdmin = () => !!JSON.parse(localStorage.getItem('admin-user'));

export const getCurrentUserExtId = () => {
  const isAdminUser = checkCurrentUserIsAdmin();
  try {
    if (isAdminUser) {
      return JSON.parse(sessionStorage.getItem('admin-access-tspExtId'));
    } else {
      return JSON.parse(localStorage.getItem('USER'))?.tsp_ext_id;
    }
  } catch {
    return '';
  }
};

export const getShowVatInvoice = () => {
  return JSON.parse(sessionStorage.getItem('show-vat-invoice')) || false;
};

export const hasTspAnyVatInoviceRide = (rideCountStatus = {}) => {
  const fieldsTocheck = ['approved', 'pending_approval', 'pending_request', 'rejected'];
  return fieldsTocheck.map(field => rideCountStatus[field] || 0).reduce((a, b) => a + b, 0) > 0;
};