import React, { useState, useEffect, Suspense, lazy, useRef, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router';
import get from "lodash/get";
import classNames from 'classnames';
import Switch from "react-switch";

import {
	getData,
	storeData,
	sendGoogleAnalyticsEvent,
	getEnvData,
	sendErrorToInstana,
	useOutsideClick,
	toastLogout,
	orAssign,
	andAssign,
	ternary
} from "helpers";
import { unsubscribeFCM, registerNotification } from "notification";
import { LanguageContext } from "contexts/language";
import { getLoginSupportNumbers, logout } from 'redux/action';
import { ReactComponent as LogoIcon } from 'images/sixt-logo-dark.svg';
import { ReactComponent as ProfileLogoIcon } from 'images/icons/ico_profile.svg';
import { ReactComponent as CovidFaqIcon } from 'images/icons/ico-docs.svg';
import { ReactComponent as NewsletterIcon } from 'images/icons/ico-newsletter.svg';
import Hamburger from 'images/icons/Hamburger.svg';
import { ReactComponent as TranslateIcon } from 'images/icons/translate.svg';
import { toast } from 'react-toastify';
import './styles.scss';
import { Banner, BannerType } from 'components/Banner/Banner';
import { useAnnouncementContext } from 'contexts/AnnouncementContextProvider';

const reactSwitchCommonProps = {
	"className": "react-switch",
	"uncheckedIcon": false,
	"checkedIcon": false,
	"handleDiameter": 15,
	"onColor": "#ff5f00",
	"boxShadow": "0px 1px 5px rgba(0, 0, 0, 0.6)",
	"activeBoxShadow": "0px 0px 1px 10px rgba(0, 0, 0, 0.2)",
	"height": 12,
	"width": 25
};

export default function Header({
	toggleMenu,
	adminFlow = false,
	tspName,
	tspId,
	handleStartTour = () => {
		// default implemenation
	},
	notificationEnabled = false
}) {

	const { t } = useTranslation();
	const { dismissChristmasBanner, isChristmasBannerShown } = useAnnouncementContext();

	const history = useHistory();
	const dispatch = useDispatch();
	const [language, setLanguage] = useContext(LanguageContext);
	const preferences = orAssign(getData("PREFERENCES"), {});
	const isTourEnabled = getEnvData.ENABLE_TOUR;

	const dispatcher = orAssign(useSelector(state => get(state, "login", {}))?.dispatcher, {});
	let {
		first_name = "",
		last_name = "",
		mobile = "",
		country_code,
		user_id
	} = dispatcher;

	const adminUser = orAssign(useSelector(state => get(state, "admin", {})), {});

	first_name = orAssign(first_name, get(adminUser, "firstName", ''));
	last_name = orAssign(last_name, get(adminUser, "lastName", ''));

	const dispatcher_id = orAssign(getData("USER")?.dispatcher_id, '');
	const [showModal, setShowModal] = useState(false);
	const [showNewsletterModal, setShowNewsletterModal] = useState(false);
	const [showDropdown, setShowDropdown] = useState(false);
	const [showLanguageDropdown, setShowLanguageDropdown] = useState(false);
	const [newRideNotification, setNewRideNotification] = useState(get(preferences, "newRideNotificationEnabled", true));

	// outside click handle
	const wrapperRef = useRef(null);
	const translateRef = useRef(null);
	useOutsideClick(wrapperRef, () => setShowDropdown(false));
	useOutsideClick(translateRef, () => setShowLanguageDropdown(false));

	const ContactSupport = lazy(() => import('components/contactSupport'));
	const Newsletters = lazy(() => import('components/newslettersModal'));

	useEffect(() => {
		/*  `mobile` attr will mandatorily have value, so fire post getting `mobile`
		 country_code can be null */
		if (mobile) {
			dispatch(getLoginSupportNumbers({ country_code }));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mobile]);

	async function handleLogout() {
		try {
			if (adminFlow) {
				history.push('/login');
				return;
			}
			const { access_token } = getData("USER");
			if (access_token) {
				unsubscribeFCM();
				const response = await dispatch(logout({
					access_token,
					user_id,
					type_of_user: "DISPATCHER",
					login_mode: getEnvData.LOGIN_MODE
				}));

				if ("Success" === get(response, "status")) {
					history.push('/login');
				}
				sendGoogleAnalyticsEvent("profile", {
					event_category: "home",
					event_label: "logout_click"
				});
			}
		} catch (error) {
			sendErrorToInstana("test errors", error);
		}
	}

	async function handleNewRideNotificationChange(newRideNotificationState) {
		try {
			const { user_id: userId = "" } = getData("USER") || {};

			await registerNotification(userId, {
				notification_info: [{
					type: "AVAILABLE_RIDES",
					is_enable: newRideNotificationState
				}, {
					type: "GENERAL",
					is_enable: true
				}],
				notification_language: language
			});

			storeData("PREFERENCES", {
				...preferences,
				newRideNotificationEnabled: newRideNotificationState
			});
			setNewRideNotification(newRideNotificationState);

			toast.success(t("New Ride notification settings changed successfully"));

		} catch (error) {
			toastLogout(error);
		}
	}

	function handleProfileClick(e) {
		const classList = get(e, "target.classList", []) || [];
		if (["notification-label", "react-switch-handle", "notification", "react-switch-bg"]
			.filter(clazz => classList.contains(clazz)).length !== 0
		) {
			return;
		} else {
			setShowDropdown(!showDropdown);
			sendGoogleAnalyticsEvent("profile", {
				event_category: "home",
				event_label: "icon_click"
			});
		}
	}

	function handleTranslateClick() {
		setShowLanguageDropdown(!showLanguageDropdown);
		sendGoogleAnalyticsEvent("translate", {
			event_category: "home",
			event_label: "icon_click"
		});
	}

	function handleLanguageChange(langCode) {
		setShowLanguageDropdown(false);
		setLanguage(langCode);
		registerNotification(user_id, {
			notification_info: [{
				type: "AVAILABLE_RIDES",
				is_enable: newRideNotification
			}, {
				type: "GENERAL",
				is_enable: true
			}],
			notification_language: langCode
		});
	}

	function openContactSupport(e) {
		e.preventDefault();
		e.stopPropagation();
		setShowDropdown(false);
		setShowModal(true);
	}

	function openNewsletter(e) {
		e.preventDefault();
		e.stopPropagation();
		setShowDropdown(false);
		setShowNewsletterModal(true);
		sendGoogleAnalyticsEvent("profile", {
			event_category: "home",
			event_label: "newsletter_click"
		});
	}

	function closeModal() {
		setShowModal(false);
		setShowNewsletterModal(false);
	}

	function redirectToLanding() {
		if (adminFlow) {
			history.replace('/admin/home');
		}

	}

	const showToggleMenu = () => ternary(
		toggleMenu,
		<div className="menu" onClick={toggleMenu}>
			<img className="hamburger" src={Hamburger} alt="menu" />
		</div>,
		<></>
	);

	return (
		<>
			<header className="header">
				<div className="left">

					{showToggleMenu()}
					<LogoIcon className={`${ternary(adminFlow, 'cursor-pointer logo', 'logo')}`} onClick={redirectToLanding} />
					<div className="title" >
						Driver Company Portal
						{ternary(adminFlow, <span className="admin_header_title">ADMIN</span>, '')}
					</div>
				</div>
				{ternary(adminFlow && tspName, <div className="center">{tspName} | {tspId}</div>, '')}
				<div className="right">
					<div className="corona-faq">
						<a
							href={`https://sixtridefaq.helpsite.com/categories/13597-coronavirus`}
							target="blank"
							rel="noopener noreferrer"
							className="d-flex align-items-center"
							onClick={() => {
								sendGoogleAnalyticsEvent("covid_faq", {
									event_category: "home",
									event_label: "icon_click"
								});
							}}
						>
							COVID-19 FAQs
							<CovidFaqIcon className="icon" />
						</a>
					</div>
					<div className="divider" />
					<div className="newsletter-link">
						<div onClick={openNewsletter} className="d-flex align-items-center">
							{t("NEWSLETTERS")}
							<NewsletterIcon className="icon" />
						</div>
					</div>
					<div className="divider" />
					<div className="language" onClick={handleTranslateClick} ref={translateRef}>
						{/* <img src={Translate} alt="" /> */}
						<TranslateIcon className="icon" />
						<ul className={classNames("language-dropdown", { hide: !showLanguageDropdown })}>
							{Object.entries(getEnvData.LANGUAGES).map(([langCode, languageName]) => (
								<li
									key={langCode}
									className={classNames("language-option", { active: language === langCode })}
									onClick={() => handleLanguageChange(langCode)}
								>
									{t(languageName)}
								</li>
							))}
						</ul>
					</div>
					<div className="divider" />
					<div className="user-name">
						{first_name} {last_name}
					</div>
					<div className="options" ref={wrapperRef} onClick={handleProfileClick}>
						{/* <img src={ProfileLogo} alt="" /> */}
						<ProfileLogoIcon />
						<span className="options-caret"></span>
						<ul className={classNames("option-dropdown", { hide: !showDropdown })}>
							{andAssign(
								isTourEnabled,
								(
									<li className="option" onClick={handleStartTour}>
										{t("TAKE_TOUR")}
									</li>
								)
							)}
							<li className="option link">
								<a
									href={`https://sixt.getfeedback.com/r/B8mZ4nnu?dispatcher-id=${dispatcher_id}&platform=WEB`}
									target="blank"
									rel="noopener noreferrer"
								>
									{t("FEEDBACK")}
								</a>
							</li>
							<li className="option" onClick={openContactSupport}>
								{t("CONTACT_SUPPORT")}
							</li>
							{andAssign(
								notificationEnabled,
								(
									<li className="option notification">
										<span className="notification-label">{t("NEW_RIDE_NOTIFICATION")}</span>
										<Switch
											onChange={handleNewRideNotificationChange}
											checked={newRideNotification}
											{...reactSwitchCommonProps}
										/>
									</li>
								)
							)}
							<li className="option" onClick={handleLogout}>
								{t("LOGOUT")}
							</li>
						</ul>
					</div>
				</div>
				<Suspense fallback={<div />}>
					<ContactSupport {...{ showModal, closeModal }} />
				</Suspense>
				{andAssign(
					showNewsletterModal,
					(
						<Suspense fallback={<div />}>
							<Newsletters {...{ showNewsletterModal, closeModal }} />
						</Suspense>
					)
				)}
			</header>

			{isChristmasBannerShown &&
				<Banner headerText={t('OFFLINE_BANNER_HEADER')} text={t('OFFLINE_BANNER_TEXT')} bannerType={BannerType.Error} hasCloseIcon onCloseClick={dismissChristmasBanner} />
			}
		</>
	);
}
