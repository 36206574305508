import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import { ToastContainer, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ENVDATA } from './config';

import store from './redux/store';
import App from './App';
import './i18n';
import './index.scss';

const getEnvData = ENVDATA[window.location.hostname];
function addScript(src) {
    var s = document.createElement('script');
    s.setAttribute('src', src);
    s.setAttribute('rel', "dns-prefetch");
    s.setAttribute('crossorigin', '');

    document.body.appendChild(s);
}

addScript(getEnvData.IDENTITY_SOURCE);

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
            <ToastContainer hideProgressBar closeOnClick transition={Flip} />
        </BrowserRouter>
    </Provider>, document.getElementById('root'));

// import * as serviceWorker from './serviceWorker';
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
