import styled, { css } from 'styled-components';

export const Wrapper = styled.button`
  background-color: #fe5f00;
  border: none;
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'} !important;
  height: ${({ isSmall }) => isSmall ? '32px' : '56px'};
  width: 100%;
  line-height: 1;
  color: #fff;
  font-family: 'Roboto Condensed';
  font-size: 20px;
  font-weight: 700;
  padding-inline: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover:not([disabled]) {
    opacity: 0.8;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
    `}

  ${({ isOutlined }) => isOutlined && css`
    background-color: transparent;
    border: 2px solid #fe5f00;
    color: #fe5f00;
  `}
`;
