import { useCountryContext } from 'contexts/CountryContextProvider';
import { TspRoute } from 'enums';
import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { getTSPByExt } from 'services/tsp';

export const useTsp = () => {
  const { pathname } = useLocation();
  const { countryList } = useCountryContext();

  const isCompanyProfilePage = useMemo(() => pathname.includes(TspRoute.CompanyProfile), [pathname]);

  const checkIfTspIsInEu = useCallback(
    countryCode => {
      const tspCountry = countryList.find(country => country.countryCode === countryCode);
      return !!tspCountry?.isEuCountry;
    },
    [countryList]
  );

  const getTspData = useCallback(async tspExtId => {
    if (tspExtId) {
      try {
        const { data } = await getTSPByExt(tspExtId);
        return data;
      } catch (error) {
        toast.error(error.message);
        return null;
      }
    }
  }, []);

  return {
    isCompanyProfilePage,
    checkIfTspIsInEu,
    getTspData,
  };
};
