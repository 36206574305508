import React from 'react';

import { sendGoogleAnalyticsEvent } from 'helpers';
import MobileDeviceApp from 'images/icons/mobile-device-app.png';
import AppStoreBadge from 'images/app-store-badge.png';
import playStoreBadge from 'images/google-play-badge.png';

export default function MobileSupport() {

    function handleDownloadAppClick(source) {
        sendGoogleAnalyticsEvent("not_supported", {
            event_category: "mobile",
            event_label: `app_install_link_${source}`
        });
    }

    return (
        <div className="mobile-device">
            <img className="info-image" src={MobileDeviceApp} alt="" />
            <h1 className="tagline mobile">
                Driver Company Portal works only on Desktop. For mobile, please download SIXT Dispatcher App.
            </h1>
            <div className="links">
                <a
                    className="download-link android"
                    href="https://play.google.com/store/apps/details?id=com.mydriver.dispatcher"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => handleDownloadAppClick("android")}
                >
                    <img className="playStoreBadge" src={playStoreBadge} alt="" />
                </a>
                <br />
                <a
                    className="download-link ios"
                    href="https://apps.apple.com/in/app/sx-dispatcher/id1484304291"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => handleDownloadAppClick("ios")}
                >
                    <img className="appStoreBadge" src={AppStoreBadge} alt="" />
                </a>
            </div>
        </div>
    );
}