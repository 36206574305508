import { VEHICLE_CLASSES, VEHICLES, VEHICLES_LOADING, VEHICLES_LOADED } from '../action/types';

const initialState = null;

export default (state = initialState, action = "") => {
    switch (action.type) {
        case VEHICLES: {
            return { ...state, data: action.vehicles };
        }
        case VEHICLE_CLASSES: {
            return action.vehicleClasses;
        }
        case VEHICLES_LOADING: {
            return { ...state, loading: true };
        }
        case VEHICLES_LOADED: {
            return { ...state, loading: false };
        }
        default:
            return state;
    }
};