import firebase from 'firebase/app';
import React, { useState, useEffect, lazy, Suspense, useContext } from 'react';
import { useHistory } from 'react-router';
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Joyride, { STATUS, EVENTS } from 'react-joyride';
import classNames from 'classnames';
import get from 'lodash/get';
import { toast } from 'react-toastify';

import TOUR_STEPS from './tourSteps';
import {
	storeData,
	getData,
	getSessionData,
	getEnvData,
	storeSessionData,
	sendGoogleAnalyticsEvent,
	ternary,
	orAssign,
	andAssign,
	andOp,
	checkAccessForPricingPage
} from 'helpers';
import { LanguageContext } from "contexts/language";
import { askForPermissionToReceiveNotifications } from "notification";
import NoMatch from 'components/noMatch';
import Header from 'containers/header';
import LeftNavigation from 'containers/leftNavigation';
import Footer from 'containers/footer';
const Dashboard = lazy(() => import('pages/dashboard'));
const Approvals = lazy(() => import('pages/approvals'));
const ManageRides = lazy(() => import('pages/manageRides'));
const AvailableRides = lazy(() => import('pages/availableRides'));
const PastRides = lazy(() => import('pages/pastRides'));
const Drivers = lazy(() => import('pages/drivers'));
const Vehicles = lazy(() => import('pages/vehicles'));
const Dispatchers = lazy(() => import('pages/dispatchers'));
const EditTsp = lazy(() => import('pages/tsp/editTsp'));
const Settings = lazy(() => import('pages/settings'));
const Pricing = lazy(() => import('pages/pricing'));
import './styles.scss';
import TspNotes from 'pages/tspNotes';
import { getTSPByExt } from 'services/tsp';
import VatInvoice from 'pages/vatInvoice';
import { getCurrentUserExtId, hasTspAnyVatInoviceRide } from 'pages/vatInvoice/service';
import { getVatInvoiceStatusNumbers } from 'services/kpi';
import { useTspContext } from 'contexts/TspContextProvider/TspContextProvider';
import { TspRoute } from 'enums';
import { useCountryContext } from 'contexts/CountryContextProvider';

const Layout = ({ tsp_id, adminFlow, setTspIdAdminFlow = () => {}, setIsAdminFlow = () => {} }) => {
	const history = useHistory();
	const location = useLocation();

	const { t } = useTranslation();
	const { fetchTspData } = useTspContext();
    const { fetchCountries } = useCountryContext();
	const [language] = useContext(LanguageContext);
	const ModifyRideModal = lazy(() => import('components/modifyRideModal'));
	let SnowStorm = ternary(getEnvData.SNOWFALL, lazy(() => import('react-snowstorm')), undefined);
	const isTourEnabled = getEnvData.ENABLE_TOUR;
	const isNotificationSupported = firebase.messaging.isSupported();

	const [showModal, setShowModal] = useState(false);
	const [rideData, setRideData] = useState();
	const [notificationEnabled, setNotificationEnabled] = useState(false);
	const [eventType, setEventType] = useState("");
	const [tspName, setTspName] = useState("");

	const [showVatInvoice, setShowVatInvoice] = useState(false);

	//PREFERENCES
	const preferences = orAssign(getData("PREFERENCES"), {});
	const [miniMenu, seMiniMenu] = useState(true); //get(preferences, 'collapse_menu', true) || true);
	const [startTour, setStartTour] = useState(get(preferences, "tour_status", "") !== "visited");

	const query = new URLSearchParams(location.search);

    useEffect(() => {
        fetchCountries();
    }, [fetchCountries]);

	useEffect(() => {
		fetchTspData();
	}, [fetchTspData]);

	useEffect(() => {
		if (isNotificationSupported && Notification.permission === 'granted') {
			askNotificationPermission();
		}

		async function checkAdminFlow() {
			const tspExtIdFromQueryParam = query.get("tspExtId");
			let adminAccessToken = getData("admin-access-Token") || ''; //admin flow
			if (tspExtIdFromQueryParam && adminAccessToken) {
				storeSessionData("admin-access-tspExtId", tspExtIdFromQueryParam);
				if (!tsp_id) {
					const currTSPRes = await getTSPByExt(tspExtIdFromQueryParam);
					const tspIdFromExtId = get(currTSPRes, 'data.id');
					storeSessionData("admin-access-tspId", tspIdFromExtId);
					storeSessionData("admin-access-tspName", get(currTSPRes, 'data.name'));
					setTspIdAdminFlow(tspIdFromExtId);
					setTspName(get(currTSPRes, 'data.name'));
				}

				setIsAdminFlow(true);
			}
			if (adminFlow) {
				// get tsp from localstorage and setup tspid
				const tspId = orAssign(getSessionData("admin-access-tspId"), '');
				const _tspName = orAssign(getSessionData("admin-access-tspName"), '');
				if (tspId) {
					setTspName(_tspName);
					setTspIdAdminFlow(tspId);
				} else {
					history.replace("/admin/home");
				}

			}
		}
		checkAdminFlow();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const tspExtId = getCurrentUserExtId();
		const controller = new AbortController();
		if (tspExtId) {
			const checkVatInvoiceRideCount = async () => {
				const response = (await getVatInvoiceStatusNumbers(tspExtId, controller.signal)).data;
				const hasInvoiceRides = hasTspAnyVatInoviceRide(response);
				if (hasInvoiceRides) {
					sessionStorage.setItem('show-vat-invoice', true);
					setShowVatInvoice(true);
				} else {
					sessionStorage.setItem('show-vat-invoice', false);
					setShowVatInvoice(false);
				}
			};
			checkVatInvoiceRideCount();
		}
		return () => {
			controller.abort();
		};
	}, []);

	function toggleMenu() {
		seMiniMenu(!miniMenu);
		storeData("PREFERENCES", {
			...preferences,
			collapse_menu: !miniMenu
		});

		sendGoogleAnalyticsEvent("hamburger", {
			event_category: "home",
			event_label: !miniMenu ? "collapsed" : "expanded"
		});
	}

	async function askNotificationPermission(event) {
		try {
			if (event) {
				event.preventDefault();
				event.stopPropagation();
			}
			askForPermissionToReceiveNotifications(handleNotificationClick, language);
			const preferencesFromStorage = getData("PREFERENCES") || {};
			storeData("PREFERENCES", {
				...preferencesFromStorage,
				newRideNotificationEnabled: true
			});
			setNotificationEnabled(true);
			sendGoogleAnalyticsEvent(`notification_bell`, {
				event_category: "home",
				event_label: `notification_enabled`
			});
		} catch (error) {
			console.warn("[PERMISSION NOT GRANTED]", error);
			sendGoogleAnalyticsEvent(`notification_bell`, {
				event_category: "home",
				event_label: `notification_disabled`
			});
		}
	}

	// ALL NOTIFICATION CLICKS COME HERE
	function handleNotificationClick(ridData = {}, event_type = "NEW_RESERVATION") {
		if (event_type === "ALREADY_ACCEPTED") {
			toast.info(t("THIS_RIDE_HAS_ALREADY_BEEN_TAKEN_BY_ANOTHER_DRIVER_ACCEPT_RIDES_QUICKLY_TO_NOT_LOSE_THEM"));
		} else {
			setRideData(ridData);
			setShowModal(true);
			setEventType(event_type);
		}
	}

	function handleJoyrideCallback({ status, type }) {
		if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
			setStartTour(false);
		}

		if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status) ||
			[EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)
		) {
			storeData("PREFERENCES", {
				...preferences,
				tour_status: "visited"
			});
		}
	}

	const tspNotesRoute = ternary(adminFlow, <TspNotes adminFlow={adminFlow} tsp_id={tsp_id} />, <Redirect to="/dashboard" />);

	const hasAccessToPricing = checkAccessForPricingPage();

  const getTspRoutes = () => (
      <Suspense fallback={<div className='vh-100'>&nbsp;</div>}>
        <Switch>
          <Route exact path={TspRoute.Dashboard}>
				<Dashboard adminFlow={adminFlow} tsp_id={tsp_id} />
			</Route>
			<Route exact path={`/manageRides`}>
				<ManageRides tsp_id={tsp_id} adminFlow={adminFlow} />
			</Route>
			<Route exact path={`/availableRides`}>
				<AvailableRides tsp_id={tsp_id} />
			</Route>
			<Route exact path={`/pastRides`}>
				<PastRides tsp_id={tsp_id} />
			</Route>
			<Route path={`/approvals`}>
				<Approvals adminFlow={adminFlow} tsp_id={tsp_id} />
			</Route>
			<Route path={`/drivers`}>
				<Drivers adminFlow={adminFlow} tsp_id={tsp_id} />
			</Route>
			<Route path={`/vehicles`}>
				<Vehicles adminFlow={adminFlow} tsp_id={tsp_id} />
			</Route>
			<Route path={`/dispatchers`}>
				<Dispatchers tsp_id={tsp_id} />
			</Route>
          <Route path={TspRoute.CompanyProfile}>
				<EditTsp adminFlow={adminFlow} tsp_id={tsp_id} />
			</Route>
			<Route path={`/pricing`}>
				{ternary(
					adminFlow && hasAccessToPricing,
					<Pricing adminFlow={adminFlow} tsp_id={tsp_id} />,
              <Redirect to={TspRoute.Dashboard} />
				)}
			</Route>
          {<Route path={`/vat-invoice`}>{showVatInvoice && <VatInvoice />}</Route>}
			<Route exact path={`/settings`}>
				<Settings tsp_id={tsp_id} />
			</Route>
          <Route path={`/tspNotes`}>{tspNotesRoute}</Route>

          <Route exact path='/'>
            {adminFlow && <Redirect exact from={'/'} to='/approvals' />}
            {!adminFlow && <Redirect exact from={'/'} to={TspRoute.Dashboard} />}
			</Route>
			<Route>
				<NoMatch />
			</Route>
		</Switch>
      </Suspense>
  );

	const showSnowStorm = () => SnowStorm && <Suspense fallback={<></>}>
		<SnowStorm followMouse={false} animationInterval={50} snowColor="#4984b8" />
	</Suspense>;

	return (
		<div>
			{showSnowStorm()}
			<Header
				adminFlow={adminFlow}
				toggleMenu={toggleMenu}
				handleStartTour={() => setStartTour(true)}
				notificationEnabled={andOp(isNotificationSupported && notificationEnabled && !adminFlow)}
				tspName={tspName}
				tspId={tsp_id}
			/>
			<div className={classNames("main-container-wrapper", { "mini-menu": miniMenu })}>
				{andAssign(
					isTourEnabled, <Joyride
					run={startTour}
					callback={handleJoyrideCallback}
					steps={TOUR_STEPS}
					locale={{ back: t('BACK'), close: t('CLOSE'), last: t('FINISH'), next: t('NEXT'), skip: t('SKIP') }}
					spotlightPadding={5}
					scrollToFirstStep
					scrollToSteps
					disableCloseOnEsc
					disableOverlayClose
					showSkipButton
					continuous
					disableScrollParentFix
				/>
				)}
				<aside className="side-menu-container">
					<LeftNavigation {...{
						askNotificationPermission,
						isNotificationSupported,
						notificationEnabled,
						adminFlow,
						showVatInvoice,
						hasAccessToPricing
					}} />
				</aside>
        <main className="side-menu-content">
          {tsp_id ? getTspRoutes() : <div className='loading'>{t('LOADING')}</div>}

					<Footer />
				</main>
			</div>
			<Suspense fallback={<div />}>
				<ModifyRideModal {...{
					showModal,
					setShowModal,
					rideData,
					tsp_id,
					eventType
				}} />
			</Suspense>
		</div>
	);
};

export default Layout;
