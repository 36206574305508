import { toast } from 'react-toastify';

export function toastLogout(error = {}, customErrorMessage = "") {
    const { error_code = "", message = "" } = error || {};

    // For session expiry or if user logged in on other system
    if ("unauthorized" === error_code || "unauthorized" === message) {
        toast.error("Current session has been expired because of profile update or login from another system.");
    }
    // show API error message
    else if (message) {
        toast.error(message);
    }
    // Show Handled error message
    else if (customErrorMessage) {
        toast.error(customErrorMessage);
    }
    // ultimate fallback
    else {
        toast.error("Something went wrong");
    }

}