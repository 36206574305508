import get from 'lodash/get';

import { NOTES } from './types';
import * as service from 'services/notes';
import { toastLogout } from 'helpers';

export const getNotes = (tspId, params) => async dispatch => {
    try {
        let response = await service.getNotes(tspId, params);
        if (response) {
            dispatch({
                type: NOTES,
                notes: get(response, 'data.notes')
            });
            return response.data;
        }
    } catch (error) {
        toastLogout(error); 
    }
};

