import firebase from 'firebase/app';
import 'firebase/messaging';
import get from 'lodash/get';

import { goOrangeAxios } from 'services/axios';
import { getEnvData, sendErrorToInstana, toastLogout, getData, storeData, DEFAULT_EMPTY_FUNCTION, orAssign } from "helpers";
import { getRideDetails } from "services/reservations";
import packageJson from '../../package.json';

let selectedLanguage = "en";
// -- INTERNAL HELPERS --
function isServiceWorkerSupported() {
	return "serviceWorker" in navigator && firebase.messaging.isSupported();
}

// -- INITIALIZE FIREBASE MESSAGING --
let FIREBASE_MESSAGING;
let currentToken;
if (isServiceWorkerSupported()) {
	firebase.initializeApp(getEnvData.FCM.APP_CONFIG);
	FIREBASE_MESSAGING = firebase.messaging();
	// eslint-disable-next-line react-hooks/rules-of-hooks 
	FIREBASE_MESSAGING.usePublicVapidKey(getEnvData.FCM.MESSAGING_VAPID_KEY);

}

function registerServiceWorker() {
	if (isServiceWorkerSupported()) {
		navigator.serviceWorker
			.register("./firebase-messaging-sw.js")
			.then(DEFAULT_EMPTY_FUNCTION)
			.catch(function (err) {
				console.log("Service worker registration failed, error:", err);
			});
	}
}

function listenToPushMessage(callback) {
	// When the app is foreground
	if (isServiceWorkerSupported()) {
		navigator.serviceWorker.onmessage = (e) => {
			generateNotification(e, callback);
		};
	}
}

async function generateNotification(eventData = {}, callback = DEFAULT_EMPTY_FUNCTION) {

	// ALL DATA TRANSFORMATION IS DONE HERE
	const {
		reservation_id,
		event_type = "NEW_RESERVATION",
		title = "",
		body = "",
		url = ""
	} = orAssign(get(eventData, "data.firebase-messaging-msg-data.data"), get(eventData, "data.data"));

	if (["RESERVATION_ACCEPTED"].includes(event_type)) {
		// check if previous new ride notification can be removed
		return;
	}

	// SEND NOTIFICATION   
	const pushNotification = new Notification(title, {
		icon: 'logos/144x144.png',
		body
	});

	pushNotification.onclick = async function () {
		window.focus();
		if ([
			"RESERVATION_CANCELED",
			"RESERVATION_UNASSIGNED"
		].includes(event_type)) { return; }
		// no action on click for few event types
		try {
			if (url) {
				window.open(url, '_blank');
			}
			if (reservation_id) {
				const rideData = await getRideDetails(reservation_id);
				callback(get(rideData, "data", {}), event_type);
			}
		} catch (error) {
			if (get(error, "message", "").toLowerCase() === "reservation not in requested state") {
				callback("", "ALREADY_ACCEPTED");
			}
		} finally {
			pushNotification.close();
		}
	};
}

export const registerNotification = async function (userId, params) {
	return goOrangeAxios.post(`v1/mydriver-supply/driver/${userId}/update-push-notification`, {
		...params,
		dispatcher_app_version: `DCP-${packageJson.version}`,
		os: getEnvData.OS,
		type_of_user: getEnvData.TYPE_OF_USER,
		login_mode: getEnvData.LOGIN_MODE,
		token: currentToken,
	});
};

async function registerForNotification(token) {
	
	try {
		setTimeout(async () => {
			const { user_id = "" } = getData("USER") || {};
			if (user_id) {
				const preferences = getData("PREFERENCES") || {};
				const newRideNotificationState = get(preferences, "newRideNotificationEnabled", true);

				await registerNotification(user_id, {
					token,
					notification_language: selectedLanguage,
					notification_info: [{
						type: "AVAILABLE_RIDES",
						is_enable: newRideNotificationState
					}, {
						type: "GENERAL",
						is_enable: true
					}],
				}).then();

				storeData("PREFERENCES", {
					...preferences,
					newRideNotificationEnabled: newRideNotificationState
				});
			}
		}, 0);

	} catch (error) {
		toastLogout(error);
	}
}

// -- EXPORTS --
export const askForPermissionToReceiveNotifications = async (callback, language) => {
	try {
		selectedLanguage = language || selectedLanguage;
		if (isServiceWorkerSupported()) {

			/* ⚠️ getToken internally call's 👉 `Notification.requestPermission()`
			{https://firebase.google.com/docs/reference/js/firebase.messaging.Messaging.html#request-permission}
			await Notification.requestPermission(); */

			currentToken = await FIREBASE_MESSAGING.getToken();
			registerForNotification(currentToken);  //send refreshed token to BACKEND
			console.log('[ASK PERMISSION] [INITIALIZE FCM] Token : ', currentToken);

			registerServiceWorker();
			listenToPushMessage(callback);

			// ♻️LISTEN TO TOKEN REFRESH
			FIREBASE_MESSAGING.onTokenRefresh(() => {
				FIREBASE_MESSAGING.getToken().then((refreshedToken) => {
					currentToken = refreshedToken;
					registerForNotification(currentToken); //send refreshed token to BACKEND
				}).catch((error) => {
					sendErrorToInstana("REFRESH FCM TOKEN RETRIEVAL", error);
				});
			});
		}
	} catch (error) {
		console.error(error);
	}
};



export const unsubscribeFCM = async () => {
	try {
		await FIREBASE_MESSAGING.deleteToken(currentToken);
	} catch (error) {
		sendErrorToInstana("[DELETE FCM TOKEN]", error);
	}
};