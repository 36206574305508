import { goOrangeAxios } from './axios';
import { getEnvData } from "helpers";

export async function getVehiclesWithStatus(tspId, params) {
    return goOrangeAxios.post(`/v1/mydriver-supply/tsp/${tspId}/vehicles`, { ...params });
}

export async function getAvailableVehicles(tspId, params = {}) {
    return goOrangeAxios.post(`/v1/mydriver-supply/tsp/${tspId}/vehicles`, {
        php: false,
        available_vehicles: false,
        allow_inactive: true,
        ...params
    });
}

export const getVehiclesByExt = async function (extId) {
    return goOrangeAxios.get(`v1/mydriver-supply/vehicle/${extId}`);
};

export async function addVehicle(tspId, params = {}) {
    return goOrangeAxios.post(`/v1/mydriver-supply/vehicles`, {
        ...params,
        tspId
    });
}

export async function updateVehicle(extId,tspId, params = {},) {
    return goOrangeAxios.put(`v1/mydriver-supply/vehicle/${extId}`, {
        ...params,
        tspId
    });
}

export async function getVehicleCatList() {
    return goOrangeAxios.get(`/v1/mydriver-supply/vehicle/acriss-codes?includeOnDemand=true`);
}

export async function updateVehiclePartitionScreenFlag(params) {
    return goOrangeAxios.put(`/v1/mydriver-supply/vehicle/vehicle-partition-screen`, {
        "vehicle_partition_screens": [params],
        type_of_user: getEnvData.TYPE_OF_USER
    });
}

