import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import moment from 'moment';

import './styles.scss';

const ViewModal = ({
    showViewModal,
    closeViewModel = () => {
        // default implementation
    },
    viewContent,
}) => {
    const { t } = useTranslation();
    return (
        <Modal
            dialogClassName="view-modal"
            show={showViewModal}
            onHide={closeViewModel}
            animation={false}
            size="lg"
            backdrop
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {t("COMPANY_NOTES")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="note-body">
                    <div className="date">
                        <span className="head-label">{t("DATE")}</span>
                        <span className="head-value">
                            {moment(viewContent.createdAt).calendar(null, {
                                sameDay: `[${t("TODAY")}] HH:mm`,
                                nextDay: `[${t("TOMORROW")}] HH:mm`,
                                lastDay: `[${t("YESTERDAY")}] HH:mm`,
                                nextWeek: `DD/MM/YYYY HH:mm`,
                                lastWeek: `DD/MM/YYYY HH:mm`,
                                sameElse: 'DD/MM/YYYY HH:mm'
                            })}
                        </span>
                    </div>
                    <hr />

                    <div className="category-info-align">
                        <div className="category-info">
                            <span className="head-label">{t("CATEGORY")}</span>
                            <span className="head-value">
                                {viewContent.category}
                            </span>
                        </div>
                        <div className="category-info category-margin">
                            <span className="head-label">{t("SUB_CATEGORY")}</span>
                            <span className="head-value">
                                {viewContent.subCategory}
                            </span>
                        </div>
                    </div>
                    <hr />
                    <div className="input_container">
                        <span className="textarea-label">{t("NOTES")}</span>
                        <textarea
                            className="fix-size"
                            placeholder="No note available"
                            rows="5"
                            value={viewContent.comment}
                        >
                        </textarea>
                    </div>
                    <div className="user-info">
                        <span className="head-label">{t("ADDED_BY")}</span>
                        <span className="head-value">
                            {viewContent.user}
                        </span>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer >
                <div className="button_container">
                    <button className="btn active" onClick={closeViewModel}>{t("CLOSE")}</button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default ViewModal;