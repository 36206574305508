import React from 'react';

import OldBrowser from 'images/icons/old_browser.svg';

export default function BrowserSupport() {

    return (
        <div className="browser-not-supported">
            <img className="info-image" src={OldBrowser} alt="" />
            <h2 className="tagline">Oops!</h2>
            <p className="heading">
                <b>SIXT Driver Company Portal</b> is not supported on this browser.
                    </p>
            <br />
            <p className="tagline">
                You are using an <strong>outdated</strong> browser.
                        <br />
                Please <a className="redirect" href="http://browsehappy.com/" target="_blank" rel="noopener noreferrer">upgrade your browser</a> for more security, speed and best experience on this site.
                    </p>
        </div>
    );
}