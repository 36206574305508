import { debounce } from 'helpers';
import React, { createContext, useCallback, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { getCountries } from 'services/tsp';

const CountryContext = createContext();

export const CountryContextProvider = ({ children }) => {
    const [countryList, setCountryList] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);

    const fetchCountries = useCallback(async () => {
        try {
            const { data } = await getCountries();
            const list = data?.countries || [];

            setCountryList(
                list
                    .map(country => ({
                        label: country.name,
                        name: country.name,
                        id: country.id,
                        value: country.id,
                        countryCode: country.countryCode,
                        isEuCountry: country.isEuCountry,
                    }))
                    .sort((a, b) => (a.label < b.label ? -1 : 1))
            );
        } catch (error) {
            toast.error(error.message);
            setCountryList([]);
        }
    }, []);

    const searchEuCountryFromSearchString = (searchText, ignoredCountryCode) => countryList.filter(
        d => d.isEuCountry
            && d?.label?.toLowerCase()?.includes(searchText?.toLowerCase())
            && d.countryCode !== ignoredCountryCode);

    const searchEuCountryFromSearchStringDebounced =
        debounce(async (searchText, ignoredCountryId) => searchEuCountryFromSearchString(searchText, ignoredCountryId), 300);


    return (
        <CountryContext.Provider
            value={{
                countryList,
                fetchCountries,
                selectedCountry,
                setSelectedCountry,
                searchEuCountryFromSearchString,
                searchEuCountryFromSearchStringDebounced
            }}>
            {children}
        </CountryContext.Provider>
    );
};

/**
 * @typedef {Object} Country
 * @property {String} countryCode
 * @property {Number} value
 * @property {boolean} isEuCountry
 * @property {String} label
 */

/**
 * @typedef {Object} CountryContext
 * @property {Array<Country>} countryList
 * @property {() => Promise<void>} fetchCountries
 * @property {Country} selectedCountry
 * @property {() => React.Dispatch<Country>} setSelectedCountry
 * @property {(searchText: string) => React.Dispatch<Country>} searchEuCountryFromSearchString
 * @property {(searchText: string, duration: number) => React.Dispatch<Country>} searchEuCountryFromSearchStringDebounced
 */

/**
 * 
 * @returns {CountryContext} CountryContext
 */
export const useCountryContext = () => {
    const context = useContext(CountryContext);

    if (!context) {
        throw new Error('useCountryContext must be used within a CountryContextProvider');
    }

    return context;
};
