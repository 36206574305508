import get from 'lodash/get';

import { toastLogout } from 'helpers';
import { VEHICLES, VEHICLES_LOADED, VEHICLES_LOADING, VEHICLE_CLASSES } from './types';
import * as service from 'services/vehicles';


export const getAvailableVehicles = (tspId, params) => async dispatch => {
    try {
        dispatch({ type: VEHICLES_LOADING });
        const response = await service.getAvailableVehicles(tspId, params);
        if (response) {
            dispatch({
                type: VEHICLES,
                vehicles: get(response, 'data.vehicles')
            });
            return response.data;
        }
    } catch (error) {
        toastLogout(error);
    } finally {
        dispatch({ type: VEHICLES_LOADED });
    }
};

export const clearAvailableVehicles = () => {
    return {
        type: VEHICLES,
        vehicles: null
    };
};


export const clearVehicleClasses = () => {
    return {
        type: VEHICLE_CLASSES,
        vehicleClasses: null
    };
};
