import get from 'lodash/get';

import { toastLogout } from 'helpers';
import { CATEGORY } from './types';
import * as service from 'services/tsp';


export const getCategoryList = () => async dispatch => {
    try {
        const response = await service.getCategories();
        if (response) {
            dispatch({
                type: CATEGORY,
                category: get(response, 'data.notesCategories', null)
            });
            return response.data;
        }
    } catch (error) {
        toastLogout(error);
    }
};




