import React, {useEffect} from 'react';
import { useHistory } from 'react-router';

 import { removeData } from "helpers";
import './styles.scss';

const AdminLogin = ({setAdminFlow}) => {

   
    const history = useHistory();

   
    useEffect(() => {
        removeData("USER");
        setAdminFlow();
        history.push("/admin/home");
        // eslint-disable-next-line
    }, []);

    return ( <div className="auth-loader">
            <img src={require('../../images/sixt-logo-dark.svg').default} alt="sixt-logo" />
            <img className="loading" src={require('../../images/loading.svg').default} alt="loading-icon" />
        </div>
    );
};

export default AdminLogin;

