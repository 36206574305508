import { RESERVATIONS, RESERVATIONS_LOADING, RESERVATIONS_LOADED } from '../action/types';

const initialState = null;

export default (state = initialState, action = "") => {
    switch (action.type) {
        case RESERVATIONS: {
            return { ...state, data: action.reservations };
        }
        case RESERVATIONS_LOADING: {
            return { ...state, loading: true };
        }
        case RESERVATIONS_LOADED: {
            return { ...state, loading: false };
        }
        default:
            return state;
    }
};