export const AllowedYearsInDOB = 200;

export const isGreaterThanXYears = (date, X = AllowedYearsInDOB) => {
    if (new Date().getFullYear() - new Date(date).getFullYear() > X) {
        return true;
    }
    return false;
};

/**
 * Gets the formatted date for tomorrow in the format dd.mm.yyyy.
 *
 * @function
 * @returns {string} The formatted date for tomorrow (dd.mm.yyyy).
 */
export const getTomorrowDateFormatted = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    const dd = String(tomorrow.getDate()).padStart(2, '0');
    const mm = String(tomorrow.getMonth() + 1).padStart(2, '0');
    const yyyy = tomorrow.getFullYear();

    return `${dd}.${mm}.${yyyy}`;
};
