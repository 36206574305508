import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';

const Timer = ({ seconds, format = 'mm:ss', timedOut = () => {
    // default implementation
} }) => {
    const [time, setTime] = useState(seconds);

    useEffect(() => {

        if (time === 0) {
            timedOut();
            return;
        }
        const timeout = setTimeout(() => {
            setTime(time - 1);
        }, 1000);

        return () => {
            clearTimeout(timeout);
        };
    }, [time, timedOut]);

    return (
        <span className="timer">{moment.utc(time * 1000).format(format)}</span>
    );
};

export default Timer;
