import { applyMiddleware, createStore, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from '../reducers';
import packageJson from '../../../package.json';

const middlewareEnhancer = applyMiddleware(thunkMiddleware);
const composeEnhancer = (process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        name: packageJson.name,
        trace: true
    })
    : compose;

const store = createStore(rootReducer, undefined, composeEnhancer(middlewareEnhancer));

export default store;
