import React, { useState, useEffect, lazy, Suspense } from 'react';
import { useHistory } from 'react-router';
import { Switch, Route, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import useAsyncEffect from 'use-async-effect';

import AdminViewWrapper from 'containers/adminViewWrapper';
const Landing = lazy(() => import('pages/landing'));
import Layout from 'containers/layout';
import MobileSupport from 'components/mobileSupport';
import BrowserSupport from 'components/browserSupport';
import { getDispatcherDetails, setUser } from 'redux/action';
import { getBrowserInfo, getData, getSessionData, sendErrorToInstana, orAssign } from 'helpers';
import { LanguageContextProvider } from 'contexts/language';
import Login from 'pages/login';
import AdminLogin from 'pages/adminLogin';
import { CountryContextProvider } from 'contexts/CountryContextProvider';
import { TspContextProvider } from 'contexts/TspContextProvider/TspContextProvider';
import { AnnouncementContextProvider } from 'contexts/AnnouncementContextProvider';

export default function App() {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const { isDesktop, isValidBrowser } = getBrowserInfo();
    const [tsp_id, setTsp_id] = useState();
    const [isAdminFlow, setIsAdminFlow] = useState();
    let dispatcher_id = getData('USER')?.dispatcher_id; // normal flow
    const dispatcher = useSelector(state => state.login)?.dispatcher;
    let adminUser = orAssign(getData('admin-user'), ''); //admin flow

    useAsyncEffect(() => {
        if (get(window, 'onunhandledrejection')) {
            window.onunhandledrejection = function (promise, reason) {
                console.error('Promise Rejection', promise, reason);
                sendErrorToInstana('Promise Rejection', promise, reason);
            };
        }
    }, []);

    useEffect(() => {
        if (location.pathname === '/adminlogin') {
            setIsAdminFlow(true);
        } else if (location.pathname === '/' && !dispatcher_id && !adminUser) {
            history.replace('/login');
        }
    }, [location, adminUser, dispatcher_id, history]);

    useEffect(() => {
        if (dispatcher_id) {
            setIsAdminFlow(false);
            dispatch(setUser(null));
            if (get(dispatcher, 'tsp_id', undefined)) {
                setTsp_id(get(dispatcher, 'tsp_id'));
            } else if (!dispatcher) {
                dispatch(getDispatcherDetails(dispatcher_id));
            }
        } else if (adminUser) {
            dispatch(setUser(adminUser));
            const tspId = getSessionData('admin-access-tspId') || '';
            setTsp_id(tspId);
            setIsAdminFlow(true);
        } else {
            if (location.pathname.includes('/admin/')) {
                setIsAdminFlow(true);
            } else {
                setIsAdminFlow(false);
                dispatch(setUser(null));
                history.replace('/login');
            }
        }

        // eslint-disable-next-line
    }, [dispatcher, dispatcher_id, history, dispatch, adminUser, isAdminFlow, tsp_id]);

    const setAdminFlow = () => {
        setIsAdminFlow(true);
    };

    const redirectToAdminLogin = e => {
        if (e) e.preventDefault();
        history.replace('/adminlogin');
    };

    const setTspIdAdminFlow = val => {
        setTsp_id(val);
    };

    const getLandingPageRoutes = () => {
        const isFlowInitialized = isAdminFlow !== undefined;

        if (!isFlowInitialized) {
            return null;
        }

        return isAdminFlow ? (
            <AdminViewWrapper>
                <Suspense fallback={<div className='vh-100'>&nbsp;</div>}>
                    <Switch>
                        <Route path='/admin/*'>
                            <Landing setTspIdAdminFlow={setTspIdAdminFlow} />
                        </Route>
                        <Route path='/'>
                            <Layout adminFlow={true} tsp_id={tsp_id} setTspIdAdminFlow={setTspIdAdminFlow} />
                        </Route>
                    </Switch>
                </Suspense>
            </AdminViewWrapper>
        ) : (
            <Layout adminFlow={false} tsp_id={tsp_id} />
        );
    };

    const getLoginRoutes = () =>
        isValidBrowser &&
        isDesktop && (
            <Switch>
                <Route exact path='/login'>
                    <Login redirectToAdminLogin={redirectToAdminLogin} />
                </Route>

                <Route exact path='/adminlogin'>
                    <AdminLogin setAdminFlow={setAdminFlow} />
                </Route>
                <CountryContextProvider>
                    <TspContextProvider isAdmin={isAdminFlow}>{getLandingPageRoutes()}</TspContextProvider>
                </CountryContextProvider>
            </Switch>
        );

    return (
        <LanguageContextProvider>
            <AnnouncementContextProvider>
                <div className='App'>
                    {getLoginRoutes()}
                    {!isValidBrowser && <BrowserSupport />}
                    {!isDesktop && <MobileSupport />}
                </div>
            </AnnouncementContextProvider>
        </LanguageContextProvider>
    );
}
