import React, { useState } from 'react';

import './styles.scss';
import { ReactComponent as UploadFileIcon } from 'images/icons/ico-docs.svg';
import { ReactComponent as WarningIcon } from 'images/icons/ico_warning_red.svg';
import { toast } from 'react-toastify';
import { uploadVatInvoice } from '../service';
import OverlayLoader from 'components/overlayLoader';
import DatePicker from "react-datepicker";

import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

const ALLOWED_FILE_TYPES = ['application/pdf'];

export const UploadInvoice = ({ rideData, updatingInvoice, cancelUpdateInvoice, onUploadSuccess }) => {

  const { t } = useTranslation();

  const [uploading, setUploading] = useState(false);
  const [vatInvoiceFile, setVatInvoiceFile] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [invoiceDate, setInvoiceDate] = useState(null);
  const [pdfErrorMsg, setPdfErrorMsg] = useState('');


  const checkFileType = file => {
    if (!file) { return; }
    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      toast.error(t('FILE_FORMAT_PDF_ERROR'));
      return;
    }
    setVatInvoiceFile(file);
  };

  const fileChangeHandler = e => {
    e.preventDefault();
    const uploadedFile = e.target.files && e.target.files[0];
    checkFileType(uploadedFile);
  };

  const uploadInvoiceFile = async () => {
    if (!invoiceNumber || invoiceNumber.trim() === '') {
      toast.error(t('INVOICE_NUMBER_REQUIRED'));
      return;
    }
    if (!invoiceDate) {
      toast.error(t('INVOICE_DATE_REQUIRED'));
      return;
    }

    try {
      const formattedDate = format(invoiceDate, 'yyyy-MM-dd') + 'T00:00:00.000Z';
      setUploading(true);
      await uploadVatInvoice(vatInvoiceFile, rideData.reservationId, invoiceNumber, formattedDate);
      toast.success(t('INVOICE_UPLOADED_SUCCESS'));
      setInvoiceNumber('');
      setInvoiceDate(null);
      onUploadSuccess();
    } catch (err) {
      toast.error(t('UPLOAD_FAILED'));
    } finally {
      setUploading(false);
    }
  };

  const onPdfLoadErrorHandler = er => {
    const msg = er?.message || t('ERROR_LOADING_PDF');
    toast.error(msg);
    setPdfErrorMsg(msg);
  };

  const pdfErrorComponent = () => <div className='pdf-error'>
    <span>{pdfErrorMsg}</span>
    <WarningIcon />
  </div>;

  const showUploadedFile = () => (
    <div className='invoice-file'>
      <Document
        file={vatInvoiceFile}
        onLoadSuccess={({ numPages }) => setTotalPages(numPages)}
        onLoadError={onPdfLoadErrorHandler}
        error={pdfErrorComponent()}
      >
        {Array.apply(null, Array(totalPages))
          .map((x, i) => i + 1)
          .map(page => <Page pageNumber={page} key={page} />)}
      </Document >
    </div>
  );

  const fileDropHandler = e => {
    e.preventDefault();
    e.stopPropagation();
    const uploadedFile = e.dataTransfer.files && e.dataTransfer.files[0];
    checkFileType(uploadedFile);
  };

  // this handler prevents the default browser behaviour of dragging a file over
  const fileDragOverHandler = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const removeUploadedFile = () => setVatInvoiceFile(null);

  const showFileUploadInput = () => (
    <div className='invoice-file invoice-file-upload'>
      <div className='upload-input-container' onDrop={fileDropHandler} onDragOver={fileDragOverHandler}>
        <label htmlFor='invoiceInput' >
          <span>{t('DRAG_DROP_INVOICE')}</span>
          <UploadFileIcon className='upload-icon' />
        </label>
        <input id='invoiceInput' type='file' onChange={fileChangeHandler} />
      </div>
    </div>
  );

  return (
    <>
      <div className='invoice-upload-container'>
        <div className='upload-file-btns'>
          {vatInvoiceFile && <button className='btn upload-another-btn' onClick={removeUploadedFile}>{t('CHANGE_INVOICE')}</button>}
          {updatingInvoice && <button className='btn upload-another-btn' onClick={cancelUpdateInvoice}>{t('CANCEL_CHANGES')}</button>}
        </div>
        <div className='invoice-section'>
          {
            vatInvoiceFile ?
              showUploadedFile() :
              showFileUploadInput()
          }
        </div>
      </div>
      <div className='inovice-modal-footer'>
        <div className='rejection-comment-container'>
          <div className='input-field'>
            <label>{t('INVOICE_NUMBER')}*:</label>
            <input type='text' placeholder={t('INVOICE_NUMBER')} value={invoiceNumber} onChange={e => setInvoiceNumber(e.target.value)} />
          </div>

          <div className='input-field'>
            <label>{t('INVOICE_DATE')}*:</label>
            <DatePicker
              selected={invoiceDate}
              maxDate={new Date()}
              onChange={date => setInvoiceDate(date)}
              dateFormat="d MMM yyyy"
              shouldCloseOnSelect={true}
            />
          </div>

        </div>
        <button className='btn btn_primary' onClick={uploadInvoiceFile} disabled={uploading || !vatInvoiceFile}>
          {t('UPLOAD_INVOICE')}
        </button>
      </div>
      {uploading && <OverlayLoader label={`${t('UPLOADING_INVOICE')}...`} />}
    </>
  );
};