import { goOrangeAxios } from './axios';

export const generateOtp = async function (params) {
    return goOrangeAxios.post(`v1/mydriver-supply/user/generate-otp`, params);
};

export const login = async function (params) {
    return goOrangeAxios.post(`/v1/mydriver-supply/user/login`, params);
};

export const getDispatcherDetails = async function (dispatcherId, params) {
    return goOrangeAxios.get(`/v1/mydriver-supply/dispatcher/${dispatcherId}/details`, params);
};

export const logout = async function (params) {
    return goOrangeAxios.post(`/v1/mydriver-supply/user/logout`, params);
};

export const getLoginSupportNumbers = async function (params) {
    return goOrangeAxios.get(`v1/mydriver-supply/zone/login-support-numbers`, { params });
};