import { goOrangeAxios } from './axios';
import { getData } from 'helpers';

export const getReservations = async function (params) {
    return goOrangeAxios.post('v1/mydriver-reservation/fetch-reservations', {
        //local_timezone: moment.tz.guess(true),
        ...params
    });
};

export const getRideDetails = async function (rideId) {
    const { dispatcher_id, tsp_id } = getData("USER") || {};
    return goOrangeAxios.get(`v1/mydriver-reservation/reservation/${rideId}/detailsV2`, {
        params: { tsp_id, dispatcher_id }
    });
};

export async function updateReservationStatus(rideId, reqBody) {
    return goOrangeAxios.post(`v1/mydriver-reservation/reservation/${rideId}/update-status`, {
        ...reqBody
    });
}

export const getRejectionReasons = function (language, reservationId) {
    return goOrangeAxios.get(`v1/mydriver-ride-monitoring/dispatch-rejection-reasons`, {
        params: { language, "reservation_id": reservationId }
    });
};

export const rejectRide = function (reqBody) {
    return goOrangeAxios.post(`/v1/mydriver-ride-monitoring/dispatch-automation/dispatcher-response`, {
        ...reqBody
    });
};

export const getTrackingLink = function (reservationId, dispatcherId) {
    return goOrangeAxios.get(`v1/mydriver-reservation/reservation/track/driver-link`, {
        params: { "reservation-id": reservationId, "dispatcher-id": dispatcherId }
    });
};
