const DATA_STATUS = Object.freeze({
	LOADING: "LOADING",
	LOADED: "LOADED",
	NO_DATA_FOUND: "NO_DATA_FOUND"
});

const ACCESS_ROLES = {
	DOC_RULE_EDITOR: 'sixt_ride_doc_rule_editor',
	PRICING_ADMIN: 'ride_supply_pricing_admin',
};

const ZONE_TYPES = {
	WORLD: "world",
	CONTINENT: "continent",
	COUNTRY: "country",
	CITY: "city",
	MARKET: "market",
	OVERRIDE: "override",
	BLACK: "black",
	SPECIAL: "special",
	ROAMING_ONDEMAND: "roaming_on_demand",
	ROAMING_SCHEDULED: "roaming_scheduled"
};

const Routes = {
	ADMIN_HOME: '/admin/home',
	ADMIN_TSP_LIST: '/admin/tsp/list',
	ADMIN_TSP_DOCUMENTS: '/admin/tsp/documents',
	ADMIN_DOC_MANAGEMENT: '/admin/doc-management',
	ADMIN_TSP_ADD: '/admin/tsp/list/add-new',
};

const VAT_INVOICE_RIDES = {
	ALL: 0,
	ONLY_NOT_REQUIRED: 1,
	ONLY_REQUIRED: 2,
};

export {
	DATA_STATUS,
	ACCESS_ROLES,
	ZONE_TYPES,
	Routes,
	VAT_INVOICE_RIDES
};

export { RevGuardRegType } from './RevGuardRegType';
export { TypeOfUser } from './TypeOfUser';
export { TspRoute } from './TspRoute';
export { Colors } from './Colors';
export { AddTspState } from './AddTspState';
export { PresentInEUCountries } from './PresentInEUCountries';
export { LoaderSize } from './LoaderSize';
export { VerificationStatus } from './VerificationStatus';
export { DocumentApprovalStatus } from './DocumentApprovalStatus';
