import React, { useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import './styles.scss';
import { checkCurrentUserIsAdmin, downloadVatInovice, updateVatInvoiceStatus } from 'pages/vatInvoice/service';
import { VAT_INVOICE_STATUS } from '../config';
import OverlayLoader from 'components/overlayLoader';
import { checkAbortedError } from 'helpers';
import DatePicker from "react-datepicker";
import { useTranslation } from 'react-i18next';
import { fromUTCStringToLocal } from 'services/pricing';
import { format } from 'date-fns';

const getTspActionButtonText = (t, rideData) => {
  switch (rideData.status) {
    case VAT_INVOICE_STATUS.PENDING_REQUEST_TSP_VAT_INVOICE:
      return t('UPLOAD_INVOICE');
    case VAT_INVOICE_STATUS.PENDING_APPROVAL_TSP_VAT_INVOICE:
      return t('REUPLOAD_INVOICE');
    case VAT_INVOICE_STATUS.REJECTED_TSP_VAT_INVOICE:
      return t('UPLOAD_UPDATED_INVOICE');
    case VAT_INVOICE_STATUS.APPROVED_TSP_VAT_INVOICE:
      return t('DOWNLOAD_INVOICE');
  }
};

export const DownloadInvoice = ({ rideData, updateInvoice, successHandler }) => {

  const { t } = useTranslation();

  const [vatInvoiceFile, setVatInvoiceFile] = useState(null);
  const [downloading, setDownloading] = useState(false);
  const [approving, setApproving] = useState(false);
  const [rejecting, setRejecting] = useState(false);
  const [totalPages, setTotalPages] = useState(null);
  const [rejectionComment, setRejectionComment] = useState('');
  const [rejectionInProgress, setRejectionInProgress] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState(rideData?.invoiceNumber);
  const [invoiceDate, setInvoiceDate] = useState(rideData.invoiceDate ? fromUTCStringToLocal(rideData.invoiceDate) : null);

  const isAdminUser = checkCurrentUserIsAdmin();
  const ongoingProcess = downloading || approving || rejecting;

  useEffect(() => {
    const controller = new AbortController();
    setInvoiceNumber(rideData?.invoiceNumber);
    setInvoiceDate(rideData.invoiceDate ? fromUTCStringToLocal(rideData.invoiceDate) : null);
    if (rideData.fileId) {
      fetchVatInvoice(controller.signal);
    }
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rideData]);

  const fetchVatInvoice = async (signal) => {
    try {
      setDownloading(true);
      const response = await downloadVatInovice(rideData.fileId, signal);
      const file = new File([response.data], `invoice-ride-${rideData.reservationId}.pdf`, { type: 'application/pdf' });
      setVatInvoiceFile(file);
      setDownloading(false);
    } catch (err) {
      if (checkAbortedError(err)) {
        return;
      }
      toast.error(err.message);
      setDownloading(false);
    }
  };

  const tspActionHandler = () => {
    if (rideData.status === VAT_INVOICE_STATUS.APPROVED_TSP_VAT_INVOICE) {
      downloadFile();
    } else {
      updateInvoice();
    }
  };

  const approveInvoice = async () => {
    if (!invoiceNumber || invoiceNumber.trim() === '') {
      toast.error(t('INVOICE_NUMBER_REQUIRED'));
      return;
    }
    if (!invoiceDate) {
      toast.error(t('INVOICE_DATE_REQUIRED'));
      return;
    }
    try {
      setApproving(true);
      const formattedDate = format(invoiceDate, 'yyyy-MM-dd') + 'T00:00:00.000Z';
      const reqBody = {
        status: VAT_INVOICE_STATUS.APPROVED_TSP_VAT_INVOICE,
        invoiceNumber,
        invoiceDate: formattedDate
      };
      await updateVatInvoiceStatus(rideData.reservationId, reqBody);
      toast.success(t('INVOICE_APPROVED_SUCCESS'));
      setInvoiceNumber('');
      setInvoiceDate(null);
      setTimeout(() => {
        successHandler();
      });
    } catch (err) {
      toast.error(t('ERROR_INVOICE_APPROVAL'));
    } finally {
      setApproving(false);
    }

  };

  const startRejectionProcess = () => {
    setRejectionInProgress(true);
  };

  const cancelRejectionProcess = () => {
    setRejectionInProgress(false);
  };

  const rejectInvoice = async () => {
    if (!rejectionComment || rejectionComment.trim() === '') {
      toast.error(t('REJECTION_COMMENT_REQUIRED'));
      return;
    }
    try {
      setRejecting(true);
      const formattedDate = format(invoiceDate, 'yyyy-MM-dd') + 'T00:00:00.000Z';
      const reqBody = {
        status: VAT_INVOICE_STATUS.REJECTED_TSP_VAT_INVOICE,
        comment: rejectionComment,
        invoiceNumber,
        invoiceDate: formattedDate,
      };
      await updateVatInvoiceStatus(rideData.reservationId, reqBody);
      toast.success(t('INVOICE_REJECTED_SUCCESS'));
      setRejectionComment('');
      setInvoiceNumber('');
      setInvoiceDate(null);
      setTimeout(() => {
        successHandler();
      });
    } catch (err) {
      toast.error(t('ERROR_INVOICE_REJECT'));
    } finally {
      setRejecting(false);
      setRejectionInProgress(false);
    }
  };

  const downloadFile = () => {
    if (!vatInvoiceFile) {
      return;
    }
    const blobUrl = URL.createObjectURL(vatInvoiceFile);
    const newWindow = window.open(blobUrl);
    setTimeout(() => newWindow.document.title = vatInvoiceFile.name, 1000);
  };

  const showDocument = () => vatInvoiceFile &&
    <Document
      file={vatInvoiceFile}
      onLoadSuccess={({ numPages }) => setTotalPages(numPages)}
      onLoadError={() => { toast.error(t('ERROR_LOADING_PDF')); }}
    >
      {Array.apply(null, Array(totalPages))
        .map((x, i) => i + 1)
        .map(page => <Page pageNumber={page} key={page} />)}
    </Document >;

  const getRejectionCommentInput = () => isAdminUser && rideData.status === VAT_INVOICE_STATUS.PENDING_APPROVAL_TSP_VAT_INVOICE &&
    <div className='rejection-comment-container'>
      <input type='text' placeholder={t('ENTER_REJECTION_REASON')} value={rejectionComment} onChange={e => setRejectionComment(e.target.value)} />
    </div>;

  const getApprovalInvoiceInputs = () => isAdminUser && rideData.status === VAT_INVOICE_STATUS.PENDING_APPROVAL_TSP_VAT_INVOICE && (
    <div className='rejection-comment-container'>
      <div className='input-field'>
        <label>{t('INVOICE_NUMBER')}*:</label>
        <input type='text' placeholder='Enter Invoice Number' value={invoiceNumber} onChange={e => setInvoiceNumber(e.target.value)} />
      </div>
      <div className='input-field'>
        <label>{t('INVOICE_DATE')}*:</label>
        <DatePicker
          selected={invoiceDate}
          maxDate={new Date()}
          onChange={date => setInvoiceDate(date)}
          dateFormat="d MMM yyyy"
          shouldCloseOnSelect={true}
        />
      </div>
    </div>
  );

  const getTspActionButtons = () => (
    <div className='inovice-modal-footer'>
      <button className='btn btn_primary' onClick={tspActionHandler} disabled={ongoingProcess}>{getTspActionButtonText(t, rideData)}</button>
    </div>
  );

  const getAdminActionButtons = () => {
    if (rideData.status === VAT_INVOICE_STATUS.PENDING_APPROVAL_TSP_VAT_INVOICE) {
      return <div className='inovice-modal-footer'>
        {
          rejectionInProgress ?
            <>
              {getRejectionCommentInput()}
              <div className='reject-confirm-btns'>
                <button className='btn btn_primary reject-confirm' onClick={rejectInvoice} disabled={ongoingProcess}>{t('CONFIRM_REJECTION')}</button>
                <button className='btn btn_primary reject-cancel' onClick={cancelRejectionProcess} disabled={ongoingProcess}>{t('CANCEL_REJECTION')}</button>
              </div>
            </>
            :
            <>
              {getApprovalInvoiceInputs()}
              <button className='btn btn_primary' onClick={approveInvoice} disabled={ongoingProcess}>{t('APPROVE_INVOICE')}</button>
              <button className='btn btn_primary btn-reject' onClick={startRejectionProcess} disabled={ongoingProcess}>{t('REJECT_INVOICE')}</button>
            </>
        }
      </div>;
    } else if (rideData.status === VAT_INVOICE_STATUS.REJECTED_TSP_VAT_INVOICE) {
      return <div className='inovice-modal-footer'>
        <button className='btn btn_primary' onClick={tspActionHandler} disabled={ongoingProcess}>{getTspActionButtonText(t, rideData)}</button>
      </div>;
    } else {
      return <div className='inovice-modal-footer'>
        <button className='btn btn_primary' onClick={downloadFile} disabled={ongoingProcess || !rideData.fileId}>{t('DOWNLOAD_INVOICE')}</button>
      </div>;
    }
  };

  return (
    <>
      <div className={`invoice-upload-container ${ongoingProcess ? 'ongoing-process' : ''}`}>
        <div className='upload-file-btns'>
          <button className='btn upload-another-btn' disabled={!vatInvoiceFile} onClick={downloadFile}>{t('DOWNLOAD_INVOICE')}</button>
        </div>
        <div className='invoice-section'>
          <div className='invoice-file'>
            {!rideData.fileId && <div className='no-file-available'>{t('NO_FILE_AVAILABLE')}</div>}
            {downloading ?
              <div className='downloading-file'>
                <span className='loader'></span>
                <span>{t('DOWNLOADING_INVOICE')}...</span>
              </div> :
              showDocument()
            }
          </div>
        </div>
        {rejecting && <OverlayLoader label={`${t('REJECTING_VAT_INVOICE')}...`} />}
        {approving && <OverlayLoader label={`${t('APPROVING_VAT_INVOICE')}...`} />}
      </div>
      {
        isAdminUser ?
          getAdminActionButtons() :
          getTspActionButtons()
      }

    </>
  );
};