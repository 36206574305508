import moment from 'moment';
import { goOrangeAxios } from './axios';


export const getAreasByIds = async (areaIds) =>
  goOrangeAxios.post(`/v1/mydriver-supply/area/details`, { ids: areaIds });

// Structure of response -------------->
// {
//   areas: [
//     {area_id: 13417, area_name: 'munich', area_type: 'city', md_area_id: 2555}
//   ]
// }
export const getAvailableAreasForTsp = async (tspId) =>
  goOrangeAxios.get(`v1/mydriver-supply/tsp/${tspId}/areas`);

export const getAreasMatchingPrefix = (areaList = [], prefix = '') =>
  areaList.filter(area => area.area_name?.match(new RegExp(prefix, 'ig')));

// Structure of response -------------->
// {
//   codes: [
//     {id: 8356, code: 'cdan'},
//     {id:8444, code: 'xdan'}
//   ]
// }
export const getAcrissCodes = async () =>
  goOrangeAxios.get(`/v1/mydriver-supply/vehicle/acriss-codes?includeOnDemand=false`);

export const getAcrissListFromMap = acrissMap => Object.values(acrissMap);
export const getAcrissCodesMatchingPrefix = (acrissList = [], prefix = '') =>
  acrissList.filter(acriss => acriss.code?.match(new RegExp(prefix, 'ig')));

export const getAcrissCodeById =
  (acrissList = []) => acrissId => acrissList.find(acriss => acriss.id === acrissId)?.code;


// ----------------------------------------------------------------------------------------------------------
// ------------------------ API calls for pricing rules ----------------------------------------------------

// this method returns all the pricing rules for specific tspId
// rideType can be 'distance', 'specific routes', 'duration'
export const getPricingRules = async ({ tspId, rideType }) =>
  goOrangeAxios.post('/v1/ride-fulfilment-pricing-manager/price-rule/fetch', {
    tsp_id: tspId,
    ride_type: getRideTypeName(rideType),
    is_admin: true,
    strategy: {
      strategy_name: 'frp',
      sub_strategy_name: getSubStrategyName(rideType),
      priority: 0
    },
  });

// this method saves a new pricing rule
export const savePricingRules = async (rules) =>
  goOrangeAxios.post('/v1/ride-fulfilment-pricing-manager/price-rule/add', rules);

// this method updates a list of pricing rule
export const updatePricingRules = async (rules) =>
  goOrangeAxios.post('/v1/ride-fulfilment-pricing-manager/price-rule/update', rules);

// this method deletes an existing pricing rule
// ruleIds parameter is an array; that means multiple rules can be deleted in one call; Hurrah!
export const deletePricingRule = async (ruleIds, updatedBy = '') =>
  goOrangeAxios.delete('/v1/ride-fulfilment-pricing-manager/price-rule', { data: { rule_ids: ruleIds, updated_by: updatedBy } });

// ----------------------------------------------------------------------------------------------------------

/*
************************************* Utility Functions ********************************************************
*/

// returns substrategy name for the given ridetype
export const getSubStrategyName = rideType => rideType !== 'racetrack' ? `by_${rideType}` : rideType;

export const getRideTypeName = rideType => rideType !== 'racetrack' ? rideType : '';

export const getRideTypeByName = name => name === '' ? 'racetrack' : name;

// changes the unit value to singular;
// ex. Minutes is converted into Minute
export const makeUnitSingular = unit => {
  const UnitsWithSingular = [{ unit: 'Hours', singular: 'Hour' }, { unit: 'km', singular: 'km' }];
  const unitWithSingular = UnitsWithSingular.find(cur => cur.unit.toUpperCase() === unit?.toUpperCase());
  return unitWithSingular ? unitWithSingular.singular : unit;
};

// converts given date into UTC time
export const toUTCString = (date = new Date()) => moment(date).format('YYYY-MM-DDTHH:mm:ss') + 'Z';

// converts given utc string to local time
export const fromUTCStringToLocal = (date = moment.utc().format('YYYY-MM-DD HH:mm:ss')) =>
  new Date(moment.utc(date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DDTHH:mm:ss'));

export const isDateBeforeCurrent = (date = moment.utc().format('YYYY-MM-DD HH:mm:ss')) => {
  return moment.utc(date, 'YYYY-MM-DD HH:mm:ss').isBefore(moment.utc());
};

export const getCurrentUTCDate = () => {
  const isoString = new Date().toISOString();
  return new Date(isoString.substr(0, isoString.length - 1));
};

export const roundTwoDecimal = num => {
  if (/^\d*(\.\d*)?$/.test(num)) {
    return Math.floor(num * 100) / 100;
  }
  return num;
};