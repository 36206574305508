import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import FilterBadges from 'components/filterBadges';
import Shimmer from 'components/shimmer';
import BootstrapTable from 'react-bootstrap-table-next';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { FILTER_INITIAL_STATE, getCompanyIdFilterLabel, getCompanyNameFilterLabel, getRideIdFilterLabel, getVatInvoiceColumnsForAdmin, getVatInvoiceColumnsForTsp, VAT_INVOICE_PAGE_SIZE, VAT_INVOICE_STATUS } from './config';
import { VatInvoiceFilters } from './filters';
import { checkCurrentUserIsAdmin, getCurrentUserExtId, getVatInvoiceRides } from './service';

import './styles.scss';
import { InvoiceModal } from './invoiceModal';
import { useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
import { checkAbortedError } from 'helpers';

const VatInvoice = () => {

  const { t } = useTranslation();
  const location = useLocation();

  const [rideList, setRideList] = useState([]);
  const [filters, setFilters] = useState(FILTER_INITIAL_STATE);
  const [loading, setLoading] = useState(true);

  // Pagination states
  const [pageOffset, setPageOffset] = useState(0);

  // Modal states
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [rideForAction, setRideForAction] = useState();

  const isNextPageAvailable = rideList.length === VAT_INVOICE_PAGE_SIZE;
  const isPrevPageAvailable = pageOffset > 0;

  const isAdminUser = checkCurrentUserIsAdmin();

  const getColumns = () => isAdminUser ? getVatInvoiceColumnsForAdmin(t, showInvoiceModal) : getVatInvoiceColumnsForTsp(t, showInvoiceModal);

  useEffect(() => {
    let queryParams = queryString.parse(location.search);
    const { status } = queryParams;
    if (Object.values(VAT_INVOICE_STATUS).includes(status)) {
      let startDate = FILTER_INITIAL_STATE.rideDate.from;
      let endDate = FILTER_INITIAL_STATE.rideDate.to;
      if (status === VAT_INVOICE_STATUS.REJECTED_TSP_VAT_INVOICE || status === VAT_INVOICE_STATUS.PENDING_REQUEST_TSP_VAT_INVOICE) {
        startDate = null;
        endDate = null;
      } else if (status === VAT_INVOICE_STATUS.APPROVED_TSP_VAT_INVOICE) {
        startDate = moment().subtract(1, 'months');
      }
      setFilters({ ...filters, status: [status], rideDate: { from: startDate, to: endDate } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    fetchVatInvoiceRices(controller.signal);
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageOffset, filters]);

  const fetchVatInvoiceRices = async (signal) => {
    try {
      setLoading(true);
      const tspExtId = getCurrentUserExtId();
      const request = {
        tspExtIds: [tspExtId],
        offset: pageOffset,
        limit: VAT_INVOICE_PAGE_SIZE,
        rideId: filters.rideId,
        status: filters.status,
        rideDate: {
          from: filters.rideDate.from,
          to: filters.rideDate.to,
        },
        customerId: filters.customerId,
        customerCompanyName: filters.customerCompanyName,
      };
      const response = await getVatInvoiceRides(request, signal);
      const vatInvoiceRides = response?.data?.rides || [];
      setRideList(vatInvoiceRides);
      setLoading(false);
    } catch (err) {
      if (checkAbortedError(err)) {
        return;
      }
      toast.error(err.message);
      setRideList([]);
      setLoading(false);

    }
  };

  const goToNextPage = () => {
    setPageOffset(pageOffset + VAT_INVOICE_PAGE_SIZE);
  };

  const goToPreviousPage = () => {
    setPageOffset(pageOffset - VAT_INVOICE_PAGE_SIZE);
  };


  const getFilterBadgeData = () =>
    (filters.rideId ? [getRideIdFilterLabel(filters.rideId)] : [])
      .concat(filters.customerId ? [getCompanyIdFilterLabel(filters.customerId)] : [])
      .concat(filters.customerCompanyName ? [getCompanyNameFilterLabel(filters.customerCompanyName)] : [])
      .concat(
        [...filters.status], []);

  const removeFilterItem = filterItem => {
    if (getRideIdFilterLabel(filters.rideId) === filterItem) {
      setFilters({ ...filters, rideId: '' });
    } else if (getCompanyIdFilterLabel(filters.customerId) === filterItem) {
      setFilters({ ...filters, customerId: '' });
    } else if (getCompanyNameFilterLabel(filters.customerCompanyName) === filterItem) {
      setFilters({ ...filters, customerCompanyName: '' });
    } else if (filters.status?.includes(filterItem)) {
      setFilters({ ...filters, status: filters.status.filter(item => item !== filterItem) });
    }
  };

  const onInoviceModalCloseHandler = (isChanged = false) => {
    setIsModalVisible(false);
    if (isChanged) {
      fetchVatInvoiceRices();
    }
  };

  const showInvoiceModal = (selectedRide) => {
    setRideForAction(selectedRide);
    setIsModalVisible(true);
  };

  return (
    <div className='vat-invoice-page'>
      <h2 className="page-title uppercase">{t('VAT_INVOICE')}</h2>

      <VatInvoiceFilters filters={filters} setFilters={setFilters} />
      <div className="filter-badges-row">
        <FilterBadges
          data={getFilterBadgeData()}
          callback={removeFilterItem}
        />
      </div>

      {loading ? <Shimmer rowCount={6} /> :
        <>
          <BootstrapTable
            noDataIndication={t("NO_DATA_FOUND")}
            classes="vat-rides-table"
            keyField='reservationId'
            data={rideList}
            columns={getColumns()}
            bordered={false} />

          <div className="navigation-footer">
            <button className="btn prev-button" disabled={!isPrevPageAvailable} onClick={goToPreviousPage}>{t("PREVIOUS_PAGE")}</button>
            {/* <span>{t("PAGE")} {currentPageNo}</span> */}
            <button disabled={!isNextPageAvailable} className="btn next-button" onClick={goToNextPage}>{t("NEXT_PAGE")}</button>
          </div>
        </>
      }

      {isModalVisible &&
        <InvoiceModal
          rideList={[rideForAction]}
          show={isModalVisible}
          onClose={onInoviceModalCloseHandler}
        />
      }
    </div>
  );
};

export default VatInvoice;