import axios from "axios";
import get from "lodash/get";

import { getEnvData, getData,getSessionData, removeData } from 'helpers';

export const goOrangeAxios = axios.create({
    baseURL: getEnvData.GO_ORANGE_AXIOS
});

goOrangeAxios.CancelToken = axios.CancelToken;
goOrangeAxios.isCancel = axios.isCancel;

// request interceptor
goOrangeAxios.interceptors.request.use(async (config) => {
    try {
        // skip auth for following API's
        const { url } = config;
        if (url.includes("/v1/mydriver-supply/user/generate-otp") ||
            url.includes("/v1/mydriver-supply/user/login") ||
            url.includes("/v1/mydriver-supply/zone/login-support-numbers")
        ) {
            return config;
        }


        const { access_token = "" } = getData("USER") || {};
        
        if (access_token) {
            config.headers.Authorization = access_token;
        } else if(getData("admin-access-Token")){
            
            config.headers.Authorization = "Bearer " + getData("admin-access-Token");
        }
        return config;
    } catch (error) {
        console.error("Error in request interceptor", error);
    }
},
    error => {
        Promise.reject(error);
    }
);

// response interceptor
goOrangeAxios.interceptors.response.use(function (response) {

    if(getData("admin-access-Token")){
        return response;
    }
    if (response.status === 401) {
        console.error("You are not authorized");
        removeData("USER");
        window.location = '/';
    }
    // for fixing same-site warning in chrome
    // response.headers["Set-Cookie"] = "HttpOnly;Secure;SameSite=Strict";
    return response;

}, function (error) {
   
   
    if (401 === get(error, "response.status") && !getSessionData("admin-access-tspId")) { // go to login page
        console.error("You are not authorized", get(error, "response.status"));
        removeData("USER");
        if (!get(window, "location.href", "").includes("/login")) {
            window.location = '/';
        }
    }
    if (error.response && error.response.data) {
        console.error("Error", get(error, "response.data"));
        return Promise.reject(error.response.data);
    }

    return Promise.reject(error.message);
});