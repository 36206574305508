export const DOCTYPE_VEHICLE = 2;
export const DOCTYPE_DRIVER = 1;
export const ALLOWED_FILE_SIZE = 1024*1024*2;// 2mb
export const ALLOWED_FILE_SIZE_IN_MB = "2mb";// 2mb
export const ALLOWED_FILE_EXTENSION = ['pdf','jpg','jpeg','png'];
export const DEFAULT_EXPIRY_DATE = '2100-01-22T08:16:56Z';


export function getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        cb(reader.result);
    };
    reader.onerror = function (error) {
        // eslint-disable-next-line no-console
        console.log('Error: ', error);
    };
}