const DEFAULT_CONFIGURATIONS = {

    // COMMON
    OS: "web",
    LOGIN_MODE: "web",
    TYPE_OF_USER: "DISPATCHER",
    SOURCE_OF_CHANGE: "RIDE_DISPATCH_WEB",
    IS_PRODUCTION: false,
    SNOWFALL: false,
    ENABLE_TOUR: false,

    CHRISTMAS_BANNER_EXPIRY_DATE: new Date('2024-01-04'),

    // PAGINATION
    PAGE_SIZE: 100,
    MODAL_PAGE_SIZE: 50,

    // INTERNATIONALIZATION
    DEFAULT_LANGUAGE: "en",
    LANGUAGES: {
        "en": "ENGLISH",
        "de": "GERMAN",
        "es": "SPANISH",
        "fr": "FRENCH",
        "it": "ITALIAN"
        // "nl": "DUTCH"
    },

    LANGUAGES_SPOKEN: {
        "en": "ENGLISH",
        "de": "GERMAN",
        "es": "SPANISH",
        "fr": "FRENCH",
        "it": "ITALIAN",
        "nl": "DUTCH",
        "ar": "ARABIC",
        "az": "AZERBAIJANI",
        "be": "BELARUSIAN",
        "my":  "BURMESE",
        "cs": "CZECH",
        "fil": "FILIPINO",
        "el":  "GREEK",
        "hi": "HINDI",
        "hu":  "HUNGARIAN",
        "ja": "JAPANESE",
        "jv": "JAVANESE",
        "ko": "KOREAN",
        "ku": "KURDISHSOMALII",
        "ms":  "MALAY",
        "mdr": "MANDARIN",
        "ps":  "PASHTO",
        "fa": "PERSIAN",
        "pl": "POLISH",
        "pt": "PORTUGUESE",
        "ro": "ROMANIAN",
        "ru": "RUSSIAN",
        "sv": "SWEDISH",
        "th":  "THAI",
        "tr": "TURKISH",
        "uk": "UKRANIAN",
        "ur": "URDU",
        "vi": "VIETNAMESE"       
    },

    // URL's
    GO_ORANGE_AXIOS: "https://api-stage.goorange.sixt.com/",
    RIDE_TRACKING_URL: "https://ride-tracking-stage.goorange.sixt.com/tripTrack/",
    FLIGHT_TRACKING_URL: "http://flightaware.com/live/flight/",
    KEYCLOAK_AUTH_SERVER_URL: 'https://identity-stage.goorange.sixt.com/auth',
    IDENTITY_SOURCE: "https://identity-stage.goorange.sixt.com/auth/js/keycloak.js",

    // FIREBASE CLOUD MESSAGING
    FCM: {
        APP_CONFIG: {
            apiKey: "AIzaSyD0cbNkPonARByh4h2-NBpZlRaBxMiDiyE",
            authDomain: "mydriver-driver-v3-dev.firebaseapp.com",
            databaseURL: "https://mydriver-driver-v3-dev.firebaseio.com",
            projectId: "mydriver-driver-v3-dev",
            storageBucket: "mydriver-driver-v3-dev.appspot.com",
            messagingSenderId: "474196702699",
            appId: "1:474196702699:web:fcb3dd94a24b3e28d115ae",
            measurementId: "G-DHP2V0XJWE"
        },
        GCM_SENDER_ID: "474196702699", // SENDER ID 👈 used directly in service worker
        // web push certificate 👇
        MESSAGING_VAPID_KEY: "BD9v2gVImCBmdq3jRRgI4cCDxnHbLHHybPl5FPZaib2cNdhsPzdUL05-dKGJKxYIFoBXQGl4ddyTVLEED5dtKqw"
    },

    // TERMS OF SERVICE IN DIFFERENT LANGUAGES
    TERMS_OF_SERVICE: {
        "en": "http://www.sixt.com/ride/tsp/tc_en.pdf",
        "de": "http://www.sixt.com/ride/tsp/tc_de.pdf",
        "fr": "http://www.sixt.com/ride/tsp/tc_fr.pdf",
        "es": "http://www.sixt.com/ride/tsp/tc_es.pdf",
        "it": "http://www.sixt.com/ride/tsp/tc_it.pdf"
    },

    // BROWSER SUPPORT
    SUPPORTED_BROWSERS: {
        "chrome": ">=51",
        "safari": ">=10",
        "firefox": ">=54",
        "opera": ">=38",
        "ie": ">11",
        "edge": ">17"
    }
};

// OVERRIDE DEFAULT CONFIGURATIONS AS PER `ENV` 👇
export const ENVDATA = {
    localhost: { 
        ...DEFAULT_CONFIGURATIONS
     },

    "dcp-dev.goorange.sixt.com": {
        ...DEFAULT_CONFIGURATIONS,
        KEYCLOAK_AUTH_SERVER_URL: 'https://identity-dev.goorange.sixt.com/auth',
        IDENTITY_SOURCE: "https://identity-dev.goorange.sixt.com/auth/js/keycloak.js",
        GO_ORANGE_AXIOS: "https://api-dev.goorange.sixt.com/",
        RIDE_TRACKING_URL: "https://ride-tracking-dev.goorange.sixt.com/tripTrack/"
    },

    "dcp-stage.goorange.sixt.com": { ...DEFAULT_CONFIGURATIONS },

    "dcp.orange.sixt.com": {
        ...DEFAULT_CONFIGURATIONS,
        KEYCLOAK_AUTH_SERVER_URL: 'https://identity-prod.orange.sixt.com/auth',
        GO_ORANGE_AXIOS: "https://api.orange.sixt.com/",
        IDENTITY_SOURCE: "https://identity-prod.orange.sixt.com/auth/js/keycloak.js",
        RIDE_TRACKING_URL: "https://ride-tracking.orange.sixt.com/tripTrack/",
        IS_PRODUCTION: true
    }
};