import React from 'react';

import CloseIcon from 'images/icons/ico_smallcross.svg';
import './styles.scss';

const FilterBadges = ({ data = [], callback = () => {
    // default implementation of callback if not provided in props
} }) => {

    return (
        <ul className="filter-badges-container">
            {data.map(filterItem => (
                <li className="badge" key={filterItem}>
                    <div className="badge-text">
                        {filterItem}
                    </div>
                    <img
                        className="close-icon"
                        src={CloseIcon}
                        alt="remove"
                        onClick={() => callback(filterItem)}
                    />
                </li>
            ))}
        </ul>
    );
};

export default FilterBadges;