import { COUNTRY } from '../action/types';

const initialState = null;

export default (state = initialState, action = {}) => {
    if(action.type === COUNTRY){
        return action.countries;
    } else {
        return state;
    }
};