import { getCurrencySymbol, numericSortFn } from 'helpers';
import moment from 'moment-timezone';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { checkCurrentUserIsAdmin } from './service';

export const VAT_INVOICE_STATUS = {
  PENDING_REQUEST_TSP_VAT_INVOICE: 'Pending Request',
  PENDING_APPROVAL_TSP_VAT_INVOICE: 'Pending Approval',
  REJECTED_TSP_VAT_INVOICE: 'Rejected',
  APPROVED_TSP_VAT_INVOICE: 'Approved'
};


const getTspActionButtonText = (t, status) => {
  const statusTextObject = {
    [VAT_INVOICE_STATUS.PENDING_REQUEST_TSP_VAT_INVOICE]: t('UPDATE'),
    [VAT_INVOICE_STATUS.PENDING_APPROVAL_TSP_VAT_INVOICE]: t('VIEW_ACTION'),
    [VAT_INVOICE_STATUS.REJECTED_TSP_VAT_INVOICE]: t('VIEW_UPDATE'),
    [VAT_INVOICE_STATUS.APPROVED_TSP_VAT_INVOICE]: t('VIEW_ACTION'),
  };
  return statusTextObject[status];
};

const getAdminActionButtonText = (t, status) => {
  const statusTextObject = {
    [VAT_INVOICE_STATUS.PENDING_REQUEST_TSP_VAT_INVOICE]: t('UPDATE'),
    [VAT_INVOICE_STATUS.PENDING_APPROVAL_TSP_VAT_INVOICE]: t('REVIEW'),
    [VAT_INVOICE_STATUS.REJECTED_TSP_VAT_INVOICE]: t('VIEW_UPDATE'),
    [VAT_INVOICE_STATUS.APPROVED_TSP_VAT_INVOICE]: t('VIEW_ACTION'),
  };
  return statusTextObject[status];
};

export const VAT_INVOICE_PAGE_SIZE = 10;

export const FILTER_INITIAL_STATE = {
  rideId: '',
  rideDate: {
    from: moment().subtract(6, 'months'),
    to: moment(),
  },
  status: [],
  customerId: '',
  customerCompanyName: '',
};

export const getRideIdFilterLabel = rideId => `Ride Id:${rideId}`;

export const getCompanyIdFilterLabel = companyId => `Company Id:${companyId}`;

export const getCompanyNameFilterLabel = companyName => `Company Name:${companyName}`;

export const formatPrice = price => price ? parseFloat(price / 100).toFixed(2) : 0;

const formatRideDate = (rideDate, t) => moment(rideDate).calendar(null, {
  sameDay: `[${t("TODAY")}]`,
  nextDay: `[${t("TOMORROW")}]`,
  lastDay: `[${t("YESTERDAY")}]`,
  nextWeek: `DD/MM/YYYY`,
  lastWeek: `DD/MM/YYYY`,
  sameElse: 'DD/MM/YYYY'
});

const formatRideTime = rideDate => moment(rideDate).format("HH:mm");

const getVatInvoiceColumns = (t, onInvoiceAction) => [
  {
    dataField: 'reservationId',
    text: t('RIDE_ID'),
    formatter: cell => <span className='bold-text'>{cell}</span>
  },
  {
    dataField: 'rideStartAt',
    text: t('RIDE_DATE'),
    sort: true,
    formatter: cell => {
      const formattedDate = formatRideDate(cell, t);
      const formattedTime = formatRideTime(cell);
      return <div className='ride-time'>{formattedDate}, {formattedTime}</div>;
    },
    sortFunc: (a, b, order) => {
      const date1 = moment(a).valueOf();
      const date2 = moment(b).valueOf();
      return numericSortFn(date1, date2, order);
    },
  },
  {
    dataField: 'origin',
    text: t('PICKUP'),
    classes: 'pickup-location',
    onlyForTsp: true,
  },
  {
    dataField: 'destination',
    text: t('DROPOFF'),
    classes: 'drop-location',
    onlyForTsp: true,
  },
  {
    dataField: 'travellingCustomerName',
    text: t('CUSTOMER_NAME'),
    onlyForTsp: true,
    formatter: cell => cell ? <span>{cell}</span> : <span>&mdash;</span>
  },
  {
    dataField: 'customerId',
    text: t('COMPANY_ID'),
    onlyForAdmin: true,
    formatter: cell => cell ? <span>{cell}</span> : <span>&mdash;</span>
  },
  {
    dataField: 'customerCompanyName',
    text: t('COMPANY_NAME'),
    onlyForAdmin: true,
    formatter: cell => cell ? <span>{cell}</span> : <span>&mdash;</span>
  },
  {
    dataField: 'finalCost',
    text: t('PAYOUT'),
    sort: true,
    sortFunc: numericSortFn,
    formatter: (cell, row) => `${getCurrencySymbol(row.currency)} ${formatPrice(cell)}`,
  },
  {
    dataField: 'status',
    text: t('STATUS'),
    formatter: (cell, row) => {
      let statusTextClass = 'status-text';
      let lastCommentSection = <></>;
      if (cell === VAT_INVOICE_STATUS.REJECTED_TSP_VAT_INVOICE) {
        statusTextClass += ' status-text-rejected';
        try {
          const commentList = JSON.parse(row.comments);
          const lastComment = commentList[commentList.length - 1].note;
          lastCommentSection = <div className='last-comment'>

            <OverlayTrigger
              key="rejection-comments-tooltip"
              placement="bottom"
              overlay={
                <Tooltip
                  id={`rejection-comments-tooltip`}
                  className='rejection-comments-container'
                >
                  {
                    commentList.reverse().map(commentObject =>
                      <div className='comments-row' key={commentObject.updated_at}>
                        <span className='updated-time-col'>{moment(commentObject.updated_at).format('DD/MM/YYYY HH:mm')}</span>
                        <span className='comment-note-col'>{commentObject.note}</span>
                      </div>
                    )
                  }
                </Tooltip>
              }
            >
              {/* <InfoIcon /> */}
              <span>{lastComment} </span>
            </OverlayTrigger>
          </div>;
        } catch {
          lastCommentSection = <div className='last-comment'>{t('Comments Not available')}</div>;
        }
      } else if (cell === VAT_INVOICE_STATUS.APPROVED_TSP_VAT_INVOICE) {
        statusTextClass += ' status-text-approved';
      }
      return <div className='status-col'>
        <span className={statusTextClass}>{cell}</span>
        {lastCommentSection}
      </div>;
    },
  },
  {
    dataField: '',
    text: t('INVOICE_ACTIONS'),
    formatExtraData: t,
    formatter: (cell, row, _, t) => {

      const isAdminUser = checkCurrentUserIsAdmin();
      const actionButtonTextFunc = isAdminUser ? getAdminActionButtonText : getTspActionButtonText;
      const buttonText = actionButtonTextFunc(t, row.status);
      return <div className="invoice-actions">
        {buttonText ?
          <button className='btn btn_primary' onClick={() => onInvoiceAction(row)}>
            {buttonText}
          </button> :
          <span>&mdash;</span>
        }
      </div>;
    },
  },
];

export const getVatInvoiceColumnsForAdmin = (t, onInvoiceAction) =>
  getVatInvoiceColumns(t, onInvoiceAction).filter(column => !column.onlyForTsp);

export const getVatInvoiceColumnsForTsp = (t, onInvoiceAction) =>
  getVatInvoiceColumns(t, onInvoiceAction).filter(column => !column.onlyForAdmin);