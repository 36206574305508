import { CUSTOMER_TYPES } from '../action/types';

const initialState = null;

export default (state = initialState, action = {}) => {
    if(action.type === CUSTOMER_TYPES){
        return action.status;
    } else {
        return state;
    }
};