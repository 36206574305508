import { ADMINUSER } from '../action/types';

export default (state = null, action = {}) => {
    if (action.type === ADMINUSER) {
        return {
            ...state,
            ...action.user
        };
    } else {
        return state;
    }
};
