import i18n from 'i18n';

const TOUR_STEPS = [
    {
        target: '.App',
        content: i18n.t("WELCOME_TO_DRIVER_COMPANY_PORTAL_CLICK_NEXT_TO_BEGIN_THE_TOUR"),
        placement: "center"
    }, {
        target: '.hamburger',
        content: i18n.t("CLICK_TO_EXPAND_COLLAPSE_THE_MENU")
    }, {
        target: '.notification-step .icon',
        content: i18n.t('RECEIVE_RIDE_RELATED_NOTIFICATIONS'),
        placement: "top",
        disableScrollParentFix: true,
        isFixed: true
    }, {
        target: '.feedback',
        content: i18n.t('SHARE_YOUR_VALUABLE_FEEDBACK_USING_THIS_LINK'),
        placement: "bottom"
    }
];

export default TOUR_STEPS;