import get from 'lodash/get';
import moment from 'moment-timezone';

import { sendGoogleAnalyticsEvent } from 'helpers';

export function getRideUpdatedTime(rowData, isLocalTime = false) {
    const bufferTime = get(rowData, "buffer_time");
    let updatedTime = get(rowData, "transportation_details.tracking_status.arrival_time", null);
    let timeDifference = 0;

    if (updatedTime) {
        updatedTime = moment(updatedTime);

        if (bufferTime && bufferTime > 0) {
            updatedTime.add(bufferTime, "minutes");
        }
        timeDifference = updatedTime.diff(moment(get(rowData, "requested_start_date")), "minutes") || 0;

        if (isLocalTime) {
            updatedTime = updatedTime.tz(rowData.origin_timezone);
        }

        updatedTime = updatedTime.calendar(null, { sameElse: 'lll' });
    }

    let updatedDropoffTime = get(rowData, "requested_end_date", null);
    if (updatedDropoffTime) {
        updatedDropoffTime = moment(updatedDropoffTime);
        if (timeDifference !== 0) {
            updatedDropoffTime.add(timeDifference, "minutes");
        }
        if (isLocalTime) {
            updatedDropoffTime = updatedDropoffTime.tz(rowData.origin_timezone);
        }
        updatedDropoffTime = updatedDropoffTime.calendar(null, { sameElse: 'lll' });
    }

    return {
        updatedTime,
        updatedDropoffTime,
        strikeThrough: !!updatedTime
    };
}

export function getUpdatedRequestStartDate(rowData) {
    const {
        requested_start_date = "",
        buffer_time = 0,
        transportation_details = {}
    } = rowData;

    let updatedTime = get(transportation_details, "tracking_status.arrival_time", null);

    if (updatedTime) {
        updatedTime = moment(updatedTime);

        if (buffer_time && buffer_time > 0) {
            updatedTime.add(buffer_time, "minutes");
        }

        return updatedTime;

    }

    return requested_start_date;
}

export function getDriverIds(drivers, filters) {
    const driver_ids = [];
    drivers.forEach(({ first_name, last_name, driver_id }) => {
        filters.drivers.forEach(driverName => {
            if (driverName === `${first_name} ${last_name}`) {
                driver_ids.push(driver_id);
            }
        });
    });
    return driver_ids;
}

export function getVehicleIds(vehicles, filters) {
    const vehicle_ids = [];
    vehicles.forEach(({ license_number, vehicle_id }) => {
        filters.vehicles.forEach((vehicleNumber) => {
            if (vehicleNumber === license_number) {
                vehicle_ids.push(vehicle_id);
            }
        });
    });
    return vehicle_ids;
}

export function sortCallback(field, page) {
    sendGoogleAnalyticsEvent("sort", {
        event_category: page,
        event_label: field
    });
}