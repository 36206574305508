import React, { createContext, useState, useEffect } from "react";
import { I18nextProvider } from 'react-i18next';
import get from "lodash/get";

import i18nInstance from "../i18n";
import { getData, storeData, getEnvData } from 'helpers';

const LanguageContext = createContext([getEnvData.DEFAULT_LANGUAGE, () => {
    // default implementation
}]);

const LanguageContextProvider = props => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const preferences = getData("PREFERENCES") || {};
    const [language, setLanguage] = useState(get(preferences, "language", getEnvData.DEFAULT_LANGUAGE));

    useEffect(() => {
        i18nInstance.changeLanguage(language);

        storeData("PREFERENCES", {
            ...preferences,
            language: language
        });
        
    }, [language, preferences]);

    return (
        <I18nextProvider i18n={i18nInstance}>
            <LanguageContext.Provider value={[language, setLanguage]}>
                {props.children}
            </LanguageContext.Provider>
        </I18nextProvider>
    );
};

export { LanguageContext, LanguageContextProvider };
