import React, { useEffect, useState } from 'react';
import FilterBox from 'components/filterBox';
import TextFieldFilter from 'components/textFieldFilter';
import VehicleIcon from 'images/icons/ico_licensePlate.svg';
import { FILTER_INITIAL_STATE, VAT_INVOICE_STATUS } from '../config';

import './styles.scss';
import DateFilter from 'components/dateFilter';
import ClearFilter from 'components/clearFilter';
import { checkCurrentUserIsAdmin } from '../service';
import { useTranslation } from 'react-i18next';

export const VatInvoiceFilters = ({ filters, setFilters }) => {

  const { t } = useTranslation();

  const [fromDate, setFromDate] = useState(filters.rideDate.from);
  const [toDate, setToDate] = useState(filters.rideDate.to);

  useEffect(() => {
    setFromDate(filters.rideDate.from);
    setToDate(filters.rideDate.to);
  }, [filters]);

  const isAdminUser = checkCurrentUserIsAdmin();

  const updateRideIdFilter = rideId => setFilters({ ...filters, rideId: rideId });

  const updateCompanyIdFilter = customerId => setFilters({ ...filters, customerId: customerId });

  const updateCompanyNameFilter = customerCompanyName => setFilters({ ...filters, customerCompanyName: customerCompanyName });

  const onStatusSelectHandler = selectedList => setFilters({ ...filters, status: selectedList });

  const setRideStartDateFrom = rideStartDate => {
    setFromDate(rideStartDate);
    setFilters({ ...filters, rideDate: { ...filters.rideDate, from: rideStartDate } });
  };

  const setRideStartDateTo = rideStartDate => {
    setToDate(rideStartDate);
    setFilters({ ...filters, rideDate: { ...filters.rideDate, to: rideStartDate } });
  };

  const clearFilters = () => {
    setFilters(FILTER_INITIAL_STATE);
    setFromDate(FILTER_INITIAL_STATE.rideDate.from);
    setToDate(FILTER_INITIAL_STATE.rideDate.to);
  };

  return (
    <div className='vat-invoice-filter'>
      <h5>{t('FILTER_BY')}:</h5>
      <TextFieldFilter value={filters.rideId} updateTextFieldFilter={updateRideIdFilter} placeholder={t('RIDE_ID')}/>
      {isAdminUser &&
        <>
          <TextFieldFilter value={filters.customerId} updateTextFieldFilter={updateCompanyIdFilter} placeholder={t('COMPANY_ID')} />
          <TextFieldFilter value={filters.customerCompanyName} updateTextFieldFilter={updateCompanyNameFilter} placeholder={t('COMPANY_NAME')} />
        </>
      }

      <FilterBox
        list={Object.values(VAT_INVOICE_STATUS)}
        selectedItems={filters.status}
        icon={VehicleIcon}
        label={t('INVOICE_STATUS')}
        searchPlaceholder={t('SEARCH_INVOICE_STATUS')}
        multipleSelect
        callback={onStatusSelectHandler}
      />

      <DateFilter
        fromDate={fromDate?.valueOf() || null}
        toDate={toDate?.valueOf() || null}
        setFromDate={setRideStartDateFrom}
        setToDate={setRideStartDateTo}
        locale={"en"}
        showDaySelectorButton={true}
        shouldCloseOnSelect={true} />

      <ClearFilter onCLick={clearFilters} />

    </div>
  );
};