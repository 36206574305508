import { goOrangeAxios } from './axios';

export const getKpiSummary = async function (tspId, params) {
    return goOrangeAxios.get(`v1/mydriver-ride-kpi/tsp/${tspId}/kpiSummary`, { params });
};

export const getKpiDetails = async function (tspId, params) {
    return goOrangeAxios.get(`v1/mydriver-ride-kpi/tsp/${tspId}/kpiDetails`, { params });
};

export const getRatingsDetails = async function (tspId, params) {
    return goOrangeAxios.get(`v1/mydriver-ride-kpi/tsp/${tspId}/ratings`, { params });
};

export const getVatInvoiceStatusNumbers = async (tspExtId, signal) => {
    return goOrangeAxios.get(`v1/ride-invoice/invoice/count-vat-invoice-status/${tspExtId}`, { signal });
};
