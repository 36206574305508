import { DRIVERS, DRIVERS_LOADED, DRIVERS_LOADING } from '../action/types';

const initialState = null;

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case DRIVERS: {
            return { ...state, data: action.drivers };
        }
        case DRIVERS_LOADING: {
            return { ...state, loading: true };
        }
        case DRIVERS_LOADED: {
            return { ...state, loading: false };
        }
        default:
            return state;
    }
};