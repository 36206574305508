import React, {useEffect, useState} from 'react';
import Keycloak from 'keycloak-js';
import { useDispatch } from 'react-redux';

import {setUser} from '../../redux/action';
import { getEnvData, storeData, getData, removeData } from "helpers";
import './styles.scss';

const AdminViewWrapper = ({children}) => {

    
     const [authenticated, setAuthenticated] = useState(false);
     const [keycloak, setKeycloak] = useState(false);
    const dispatch = useDispatch();

    const initKeyClockAuth = async () => {
        
        try {
           
            // Keyclock auth part
            const keycloakObj = Keycloak({
                "url": getEnvData.KEYCLOAK_AUTH_SERVER_URL,
                "realm": "SixtEmployees",
                "clientId": "mydriver-company-portal",
                "always-refresh-token": true
            });
            
            keycloakObj.init({ onLoad: 'login-required', promiseType: 'native', checkLoginIframe: false }).then((isAuthenticated) => {
              
                if (isAuthenticated) {
                    storeData("admin-access-Token", keycloakObj.token);
                     setKeycloak(keycloakObj);
                     setAuthenticated(isAuthenticated);
                     keycloakObj.loadUserProfile().then(user => {
                        
                       storeData("admin-user", user);
                       dispatch(setUser(user));
                       
                    });
                } else {
                    alert("Login failed");
                    document.location.href = "sixt.com";
                }
            }).catch(error => {
                alert("Login failed", error);
                document.location.href = "sixt.com";
            });

            // Token refreshing every 10 min.
            setInterval(async function () {
                try {
                    await keycloakObj.updateToken();
                    if (keycloakObj.token !== getData("admin-access-Token")) {
                        
                        storeData("admin-access-Token", keycloakObj.token);
                    }
                } catch (error) {
                    console.log(error);
                }
            }, 5000);
        } catch (error) {
            console.log("error in keycloak : ", error);
        }
    };
   

    useEffect(() => {
        removeData("USER");
        initKeyClockAuth();

        // effect dependency array
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return ( (authenticated && keycloak) ?  <div>
            {children}
    </div>:  <div className="auth-loader">
            <img src={require('../../images/sixt-logo.svg').default} alt="sixt-logo" />
            <img className="loading" src={require('../../images/loading.svg').default} alt="loading-icon" />
        </div>
    );
};

export default AdminViewWrapper;

