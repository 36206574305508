import React, { useState, useEffect, Suspense, lazy } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { FormGroup, FormLabel } from "react-bootstrap";
import classNames from 'classnames';
import get from 'lodash/get';
import Select, { components } from 'react-select';

import {
	storeData,
	sendGoogleAnalyticsEvent,
	getEnvData,
	getData,
	removeData,
	removeSessionData,
	ternary
} from "helpers";
import {
	getDispatcherDetails,
	clearDrivers,
	clearReservations,
	clearAvailableVehicles,
	clearVehicleClasses,
	clearCustomerType
} from 'redux/action';
import * as service from "services/login";
import Countries from './countries.json';
import Timer from "components/timer";
import Logo from 'images/sixt-logo.svg';
import LeftArrow from 'images/icons/left-arrow.svg';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import { Banner, BannerType } from 'components/Banner/Banner';
import { useAnnouncementContext } from 'contexts/AnnouncementContextProvider';

const Option = props => {
	return (
		<components.Option {...props}>
			{props.data.customLabel}
		</components.Option>
	);
};

const Login = ({ redirectToAdminLogin }) => {

	const history = useHistory();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { dismissChristmasBanner, isChristmasBannerShown } = useAnnouncementContext();

	const ContactSupport = lazy(() => import('components/contactSupport'));

	const LAST_LOGIN_DETAILS = getData("LAST_LOGIN_DETAILS");

	const [phoneNumber, setPhoneNumber] = useState(get(LAST_LOGIN_DETAILS, "phoneNumber", ""));
	const getCountryCodeFromEnv = () => getEnvData.IS_PRODUCTION ? Countries[0].dialCode : "+91";
	const [countryCode, setCountryCode] = useState(get(LAST_LOGIN_DETAILS, "countryCode", getCountryCodeFromEnv()));
	const [otp, setOtp] = useState("");
	const [error, setError] = useState("");
	const [hasLoggedIn, setHasLoggedIn] = useState(false);
	const [enteredPhoneNumber, setEnteredPhoneNumber] = useState(false);
	const [timedOut, setTimedOut] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const isMacLike = ternary(navigator.platform.match(/(Mac|iPhone|iPod|iPad)/i), true, false);

	const countryOptions = [
		...Countries.map(({ emoji, name, code, dialCode }) => ({
			value: dialCode,
			label: ternary(isMacLike, `${emoji} ${dialCode}`, `${code} ${dialCode}`),
			customLabel: ternary(isMacLike, `${emoji} ${name} (${dialCode})`, `${name} (${dialCode})`),
			search: ` ${dialCode} ${name} ${code} `
		}))
	];

	/*  run once to clear all cached data,
			Adding here instead as manual and auto session timeout will reach here
	*/
	useEffect(() => {
		dispatch(clearDrivers());
		dispatch(clearReservations());
		dispatch(clearAvailableVehicles());
		dispatch(clearVehicleClasses());
		dispatch(clearCustomerType());
		removeData("admin-access-Token");
		removeSessionData("admin-access-tspId");
		removeSessionData("admin-access-tspName");
		removeSessionData("admin-access-tspExtId");
		removeSessionData("show-vat-invoice");
		removeData("admin-user");

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (hasLoggedIn) {
			history.push('/');
		}
	});

	async function getOtp(e, is_retry = false) {
		if (phoneNumber) {
			try {
				const { error_code, message, data = "" } = await service.generateOtp({
					"mobile_number": `${countryCode}${phoneNumber}`,
					is_retry,
					"type_of_user": "DISPATCHER",
					login_mode: getEnvData.LOGIN_MODE
				});
				if (error_code) {
					setError(message);
					sendGoogleAnalyticsEvent("pin_click", {
						event_category: "login",
						event_label: "get_failure"
					});
				} else {
					setError("");
					if (is_retry) {
						toast.success(`Your PIN has been sent to your phone number: ${countryCode}${phoneNumber} and your email: ${data.email}`);
					}
					// adding timeout, so that component renders first and `otp` input gets created
					setTimeout(() => document.querySelector('.otp').focus(), 0);
					setEnteredPhoneNumber(true);
					sendGoogleAnalyticsEvent("pin_click", {
						event_category: "login",
						event_label: "get_success"
					});
				}

			} catch (err) {
				if (err.message === "no such active user") {
					toast.error("No such active user found");
				} else {
					toast.error("We encountered error in generating PIN");
				}
				sendGoogleAnalyticsEvent("pin_click", {
					event_category: "login",
					event_label: "get_failure"
				});
			}
		} else {
			setError("Please enter phone number");
		}
	}

	async function handleLogin() {
		try {
			if (otp) {
				setError("");
				const { error_code, message, data } = await service.login({
					"username": `${countryCode}${phoneNumber}`,
					"password": otp,
					"type_of_user": "DISPATCHER",
					login_mode: getEnvData.LOGIN_MODE
				});

				if (error_code) {
					setError(message);
					sendGoogleAnalyticsEvent("signin", {
						event_category: "login",
						event_label: "failure"
					});
				}

				if (data) {
					const { dispatcher_id } = data;
					storeData("USER", data);

					if (dispatcher_id) {
						await dispatch(getDispatcherDetails(dispatcher_id));
					}
					setHasLoggedIn(true);
					sendGoogleAnalyticsEvent("signin", {
						event_category: "login",
						event_label: "success"
					});
					storeData("LAST_LOGIN_DETAILS", {
						countryCode,
						phoneNumber
					});
				}
			} else {
				setError("Please enter PIN");
			}
		} catch (e) {
			setError(get(e, "message"));
			sendGoogleAnalyticsEvent("signin", {
				event_category: "login",
				event_label: "failure"
			});
		}
	}

	function handleNumericInput(e, setter) {
		const regex = /^[0-9\b]+$/;
		if (e.target.value === '' || regex.test(e.target.value)) {
			setter(e.target.value);
		}
	}

	function goBack() {
		setEnteredPhoneNumber(false);
		setOtp("");
		setError("");
		document.querySelector('.phone-number').focus();
		sendGoogleAnalyticsEvent("post_pin", {
			event_category: "login",
			event_label: "back"
		});
	}

	function handleEnterKey(e) {
		if (e.key === 'Enter') {
			e.preventDefault();
			e.stopPropagation();
			if (enteredPhoneNumber) {
				handleLogin();
			} else {
				getOtp(e);
			}
		}
	}

	function timerTimedOut() {
		setTimedOut(true);
	}

	function resendOtp(e) {
		setTimedOut(false);
		getOtp(e, true);
		sendGoogleAnalyticsEvent("pin_click", {
			event_category: "login",
			event_label: "resend"
		});
	}

	function openPopup(e) {
		e.preventDefault();
		e.stopPropagation();
		setShowModal(true);
		sendGoogleAnalyticsEvent("contact_support_click", {
			event_category: "login",
			event_label: enteredPhoneNumber ? "post_pin" : "pre_pin"
		});
	}

	function closeModal() {
		setShowModal(false);
	}

	function handleCountryCodeSelection() {
		sendGoogleAnalyticsEvent("login_number", {
			event_category: "login",
			event_label: "country_code"
		});
	}

	function handleCountryCodeChange(selectedOption) {
		setCountryCode(selectedOption.value);
		sendGoogleAnalyticsEvent("login_number", {
			event_category: "login",
			event_label: "country_code_selection"
		});
	}

	function handlePhoneNumberClick() {
		sendGoogleAnalyticsEvent("login_number", {
			event_category: "login",
			event_label: "login_mobile_number_focus"
		});
	}

	const resetPinComponent = () => enteredPhoneNumber && (
		<>
			<FormGroup controlId="OTP">
				<FormLabel className="login-label">PIN</FormLabel>
				<input
					className="login-input otp"
					type="tel"
					value={otp}
					onChange={(e) => handleNumericInput(e, setOtp)}
					onKeyDown={handleEnterKey}
					maxLength={4}
					autoComplete="off"
				/>
			</FormGroup>
			<p className="resend" >
				{!timedOut && <Timer seconds={60} timedOut={timerTimedOut} />}
				<span
					className={classNames("resend-otp", { disable: !timedOut })}
					onClick={resendOtp}
				>
					Resend PIN
				</span>
			</p>
		</>
	);

	const getPinComponent = () => enteredPhoneNumber ?
		<button type="button" className="button button-fill sign-in" onClick={handleLogin}>
			SIGN IN
		</button>
		:
		<button type="button" className="button button-fill get-pin" onClick={getOtp}>
			Get PIN
		</button>;

	return (
		<div className="login-container">
			{isChristmasBannerShown &&
				<Banner headerText={t('OFFLINE_BANNER_HEADER')} text={t('OFFLINE_BANNER_TEXT')} bannerType={BannerType.Error} hasCloseIcon onCloseClick={dismissChristmasBanner} />
			}
			<div className="login">
				<div className="login-card">
					<form action="" className="login-form" autoComplete="on">
						<img className="sixt-logo" src={Logo} alt="Sixt logo" />
						<h4>Welcome to Sixt</h4>
						<h6>Please login to your account!</h6>

						<p className={classNames("error", { hide: !error })} >{error}</p>
						<p
							className={classNames("back-button", { hide: !enteredPhoneNumber })}
							onClick={goBack}
						>
							<img className="back-image" src={LeftArrow} alt="Left Arrow" />
							Back
						</p>

						<FormGroup controlId="contactNumber">
							<FormLabel className="login-label">Phone Number</FormLabel>
							<div className="phone-number-contact">
								<div className="country-container">
									<Select
										className="react-select-container country-code"
										classNamePrefix="react-select"
										value={countryOptions.filter(option => option.value === countryCode)}
										options={countryOptions}
										components={{ Option }}
										onChange={handleCountryCodeChange}
										onMenuOpen={handleCountryCodeSelection}
										isDisabled={enteredPhoneNumber}
										ignoreAccents={true}
										filterOption={(option = {}, inputValue = "") => {
											const searchLabel = (get(option, "data.search", "") || "").trim().toLowerCase();
											return searchLabel.includes(inputValue.trim().toLowerCase());
										}}
									/>
								</div>
								<input
									className="login-input phone-number"
									type="tel"
									autoComplete="tel-national"
									autoFocus={true}
									value={phoneNumber}
									onClick={handlePhoneNumberClick}
									onChange={(e) => handleNumericInput(e, setPhoneNumber)}
									onKeyDown={handleEnterKey}
									readOnly={enteredPhoneNumber}
								/>
							</div>
						</FormGroup>
						{resetPinComponent()}
						{getPinComponent()}

						<p className="contact-support">
							Trouble Logging in?
							<button className="contact-support-button" onClick={openPopup}>
								Contact Support
							</button>
						</p>
						<a href="#/" className="link-toadmin" onClick={(e) => redirectToAdminLogin(e)}>Login as Admin</a>
					</form>
					<div className="login-image-container">
						<div className="login-tagline">
							<p className="login-tagline-text">
								MAXIMIZE YOUR BUSINESS <br />
								BY MANAGING IT ON SIXT!
							</p>
						</div>
					</div>
				</div>
				<Suspense fallback={<div />}>
					<ContactSupport {...{
						showModal,
						closeModal,
						headingLine: "TROUBLE_LOGGING_IN_CONTACT_SIXT_HOTLINE_NUMBERS"
					}} />
				</Suspense>
			</div>

		</div>
	);
};

export default Login;