import moment from 'moment-timezone';
import CURRENCIES from "helpers/collections/currency.json";
import Bowser from "bowser";
import get from 'lodash/get';
import memoize from 'fast-memoize';

import { matchPath, useLocation } from 'react-router-dom';
import { ENVDATA } from '../config';
import { useEffect } from 'react';
import { identity } from 'lodash';
import { toast } from 'react-toastify';
import { getData } from './storage';
import { ACCESS_ROLES } from 'enums';

export const getEnvData = ENVDATA[window.location.hostname];

// CUSTOM HOOKS
export function useEffectAsync(effect, inputs) {
    useEffect(() => {
        effect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, inputs);
}

/*
   `ref`: pass the `ref` of the object outside which the click needs to be detected
   callback: callback function to be triggered on the event
*/
export function useOutsideClick(ref, callback) {
    // Alert if clicked on outside of element
    function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target) && typeof callback === 'function') {
            callback();
        }
    }

    useEffect(() => {
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });
}

// COOKIE
export function setCookie(cname, cvalue, expireInSec) {
    let d = new Date();
    d.setTime(d.getTime() + (expireInSec * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let cx of ca) {
        let c = cx;
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}

//OTHER UTILITIES
export const matchRoute = function (path, exact = false) {
    return matchPath(window.location.pathname, { path, exact: exact });
};

export function formatDate(date, fallbackValue = "-") {
    return date ? moment(date).calendar(null, { sameElse: 'lll' }) : fallbackValue;
}

/* in-case-sensitive sorting of array of string */
export function sortStringArray(arr = []) {
    return arr.sort((a, b) => a.localeCompare(b));
}

export function getCurrencySymbol(currencyCode) {
    const symbol = CURRENCIES[currencyCode] && CURRENCIES[currencyCode].symbol;
    return symbol ? symbol : currencyCode;
}

export const getBrowserInfo = memoize(function getBrowserInfo() {
    const browser = Bowser.getParser(window.navigator.userAgent);
    const isValidBrowser = browser.satisfies(getEnvData.SUPPORTED_BROWSERS);

    return {
        isDesktop: get(browser, 'parsedResult.platform.type', "") === 'desktop',
        isValidBrowser
    };
});

export const getMapDirectionsUrl = memoize(function getMapDirectionsUrl(
    from = "", to = "", lat = "", lng = ""
) {

    let params = {
        travelmode: "driving",
        map_action: "map"
    };

    if (to) {
        params = {
            ...params,
            origin: from,
            destination: to
        };

        return `https://www.google.com/maps/dir/?api=1&${new URLSearchParams(params)}`;
    } else {
        params = {
            ...params,
            query: from,
            ...(lat && lng && { center: `${lat}, ${lng}` })
        };

        return `https://www.google.com/maps/search/?api=1&${new URLSearchParams(params)}`;
    }
});

export function capitalizeFirstLetter(string = "") {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getQueryParamArray(param = []) {
    if (Array.isArray(param)) {
        return param;
    }
    else if (param) {
        return [param];
    }
    return [];
}

export function getUniqueListBy(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()];
}

// returns fallback number for NaN
export function getNanFallback(number = 0, fallback = 0) {
    return isNaN(number) ? fallback : number;
}

export function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export function ternary(predicate, tValue, fValue) {
    return predicate ? tValue : fValue;
}
export function andOp(...vals) {
    return vals.every(identity);
}

export function orOp(...vals) {
    return vals.some(identity);
}

export function orAssign(...vals) {
    return vals.reduce((v1, v2) => v1 || v2);
}

export function andAssign(...vals) {
    return vals.reduce((v1, v2) => v1 && v2);
}

export function DEFAULT_EMPTY_FUNCTION() {
    // default function that does nothing
    // this function can be used for providing a default value to a parameter of type function
}
export function deleteValueFromArray(arr, value) {
    return arr.filter((ele) => {
        return ele != value;
    });
}

// this function opens a file(pdf or image into a new tab)
export const openFileInNewTab = async (base64Encoded, fileName) => {
    const fileExt = fileName.split('.')[fileName.split('.').length - 1];
    const decodedURI = decodeURIComponent(base64Encoded);
    let url = '';
    if (fileExt?.toLowerCase() === 'pdf') {
        url = `data:application/pdf;base64,${decodedURI}`;
    } else {
        url = `data:image/${fileExt};base64,${decodedURI}`;
    }
    try {
        // get blob url from base64 data
        const response = await fetch(url);
        const blobData = await response.blob();
        const blobUrl = URL.createObjectURL(blobData);

        const newWindow = window.open(blobUrl);
        newWindow.addEventListener('load', () => { newWindow.document.title = fileName; }, false);
    } catch {
        toast.error('Error in opening file in new tab');
    }
};

export function removeIndexFromArray(arr, idx) {
    return arr.slice(0, idx).concat(arr.slice(idx + 1));
}

export function getPayloadFromJwtToken(jwt) {
    const jwtData = jwt?.split('.')?.[1];
    const decodedJwtData = window.atob(jwtData);
    return JSON.parse(decodedJwtData);
}

export function getRolesFromJwtPayload(payload) {
    return payload?.realm_access?.roles || [];
}

export function checkAccessForPricingPage() {
    try {
        const adminToken = getData("admin-access-Token");
        if (adminToken) {
            const allRolesForCurrentUser = getRolesFromJwtPayload(getPayloadFromJwtToken(adminToken));
            return allRolesForCurrentUser.includes(ACCESS_ROLES.PRICING_ADMIN);
        }
        else {
            return false;
        }

    } catch {
        return false;
    }

}

export function numericSortFn(a, b, order) {
    if (order === 'asc') {
        return a - b;
    }
    return b - a; // desc
}

export function stringSortFn(a, b, order) {
    const aUpper = a ? a.toUpperCase() : a;
    const bUpper = b ? b.toUpperCase() : b;
    if (aUpper === bUpper) {
        return 0;
    }
    if (order === 'asc') {
        return aUpper < bUpper ? -1 : 1;
    }
    return aUpper < bUpper ? 1 : -1; // desc
}

export const checkAbortedError = err => typeof err === 'string' && err.toLowerCase() === 'canceled';

export const debounce = (func, delay) => {
    let timeoutId;

    return async (...args) => {
        clearTimeout(timeoutId);

        return new Promise(resolve => {
            timeoutId = setTimeout(() => {
                const result = func(...args);
                if (result instanceof Promise) {
                    result.then(resolve);
                } else {
                    resolve(result);
                }
            }, delay);
        });
    };
};
