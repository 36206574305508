import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import moment from "moment-timezone";
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/it';
import 'moment/locale/fr';

import { getEnvData } from "helpers";
import en from "translations/en.json";
import de from "translations/de.json";
import it from "translations/it.json";
import fr from "translations/fr.json";
import es from "translations/es.json";
import nl from "translations/nl.json";

const i18nInstance = i18n.createInstance();
i18nInstance
    //.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: false, // !getEnvData.IS_PRODUCTION,
        keySeparator: false,
        lng: getEnvData.DEFAULT_LANGUAGE,
        fallbackLng: "en",
        otherLanguages: ['en', 'de', 'it', 'fr', 'es', 'nl'],
        resources: { en, de, it, fr, es, nl },
        ns: ["translations"],
        defaultNS: "translations",
        cleanCode: true,
        react: {
            useSuspense: true,
            bindI18n: 'languageChanged',
            wait: true
        },
        interpolation: {
            escapeValue: false,                                                   // NOT NEEDED FOR REACT
            formatSeparator: ",",
            format: function (value, format) {
                if (format === 'uppercase') return value.toUpperCase();
                if (value instanceof Date) return moment(value).format(format);
                return value;
            }
        }

    }, function (err) {
        if (err) {
            // eslint-disable-next-line no-console
            console.log("Error in initializing i18next: ", err);
        }
    });

// CHANGE ALL PLUGIN LOCALES HERE
i18nInstance.on('languageChanged', function (lng) {

    // MOMENT
    moment.locale(lng);

});

export default i18nInstance;