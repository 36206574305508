function validateField(key, formControls, setFormControls) {
	const currentField = formControls[key] || {};
	const setFormControlState = (prevState) => ({
		...prevState,
		[key]: {
			...prevState[key],
			isValid: false
		}
	});

	const validationRule = currentField.validation;

	if (validationRule.required && Array.isArray(currentField.value) && !currentField.value.length) {
		setFormControls(setFormControlState);
		return false;
	}
	if (validationRule.required && typeof (currentField.value) === "string" && !currentField.value.trim()) {
		setFormControls(setFormControlState);
		return false;
	}

	if ((validationRule.maxLength && currentField.value?.length > validationRule.maxLength) || (validationRule.minLength && currentField.value.length < validationRule.minLength)) {
		setFormControls(setFormControlState);
		return false;
	}

	if (validationRule.range && currentField.value && ((+currentField.value > +validationRule.range.max) || (+currentField.value < +validationRule.range.min))) {
		setFormControls(setFormControlState);
		return false;
	}
	if (currentField.value && validationRule.pattern && !validationRule.pattern.test(currentField.value)) {
		setFormControls(setFormControlState);
		return false;
	}

	setFormControls((prevState) => ({
		...prevState,
		[key]: {
			...prevState[key],
			isValid: true
		}
	}));

	return true;
}
export function validateForm(formControls, setFormControls) {
	let isValid = true;
	try {
		for (let key in formControls) {
			let currentField = formControls[key] || {};
			if (Array.isArray(currentField)) {
				continue;
			}
			if (!currentField.validation) {
				continue;
			}
			const isFieldValid = validateField(key, formControls, setFormControls);
			if (!isFieldValid) { isValid = false; }
		}

		return isValid;
	} catch (err) {
		console.warn(err);
	}

}