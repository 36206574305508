import React from 'react';

import './styles.scss';

export default function Shimmer({ rowCount = 4 }) {
    const arraySequence = [...Array(rowCount).keys()];
    return (
        <div className="shimmer-container wrapper">
            <div className="shimmer-table">
                <div className="shimmer-table-header">
                    <div className="header-content shine"></div>
                </div>
                {
                    arraySequence.map(idx => <div className="shimmer-table-row" key={idx}>
                        <div className="row-content shine"></div>
                    </div>)
                }
            </div>
        </div>
    );
}