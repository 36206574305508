import get from 'lodash/get';

import { toastLogout } from 'helpers';
import { CUSTOMER_TYPES } from './types';
import * as service from 'services/customerType';


export const getCustomerType = (tspId) => async dispatch => {
    try {
        const response = await service.getCustomerTypeWithStatus(tspId);
        if (response) {
            dispatch({
                type: CUSTOMER_TYPES,
                status: get(response, 'data.status')
            });
            return response.data;
        }
    } catch (error) {
        toastLogout(error);
    }
};

export const clearCustomerType = () => {
    return {
        type: CUSTOMER_TYPES,
        status: null
    };
};


