import React from 'react';
import { $Bar, $Spinner } from './LoadingIndicator.styled';
import { LoaderSize } from 'enums';

export const LoadingIndicator = ({ size = LoaderSize.Medium, highlight = true }) => (
  <$Spinner size={size}>
    <$Bar highlight={highlight} size={size} />
    <$Bar highlight={highlight} size={size} />
    <$Bar highlight={highlight} size={size} />
    <$Bar highlight={highlight} size={size} />
    <$Bar highlight={highlight} size={size} />
  </$Spinner>
);