import get from 'lodash/get';

import { toastLogout, removeData, getData, storeData, getEnvData } from 'helpers';
import { SUPPORT_NUMBERS, LOGOUT, DISPATCHER_DETAILS } from './types';
import * as service from 'services/login';

export const getDispatcherDetails = (dispatcherId, params) => async dispatch => {
    try {
        let response = await service.getDispatcherDetails(dispatcherId, params);
        if (response) {
            dispatch({
                type: DISPATCHER_DETAILS,
                dispatcher: get(response, 'data')
            });
            const user = getData("USER");
            storeData("USER", { ...user, ...response.data });
            return response.data;
        }
    } catch (error) {
        toastLogout(error);
    }
};

export const logout = (params) => async dispatch => {
    try {
        let response = await service.logout(params);
        if (response) {
            removeData("USER");
            dispatch({
                type: LOGOUT
            });
            return response.data;
        }
    } catch (error) {
        toastLogout(error);
    }
};

export const getLoginSupportNumbers = (params) => async dispatch => {
    try {
        const response = await service.getLoginSupportNumbers(params);
        const supportNumbers = get(response, 'data.hotline_numbers');

        if (supportNumbers) {
            dispatch({
                type: SUPPORT_NUMBERS,
                supportNumbers
            });
            return supportNumbers;
        }
    } catch (error) {
        if (getEnvData.IS_PRODUCTION) {
            toastLogout(error);
        }
    }
};
